import {BrowserRouter} from "react-router-dom";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {GoogleOAuthProvider} from "@react-oauth/google";

import AppProviders from "./config/appProviders";
import ApplicationRoutes from "./config/ApplicationRoutes";
import {CriticalStyles} from "./config";
import {useEffect} from "react";
import ReactPixel from 'react-facebook-pixel';
import {clarity} from "react-microsoft-clarity";
import {useIsResponsive} from "./hooks/useIsResponsive";
import {setupGlobalErrorHandlers} from "./error-handlers/errorHandler";
import {CustomErrorBoundary} from "./error-handlers/errorBoundary";
import SiderContextProvider from "./context/SiderContext";
import TagManager from "react-gtm-module";

const clientId: string = process.env.REACT_APP_GOOGLE_SSO_CLIEND_ID as string;
const enablePixel: boolean = process.env.REACT_APP_ENABLE_PIXEL === "true";
const clarityId: string = process.env.REACT_APP_ENABLE_CLARITY_ID as string;

setupGlobalErrorHandlers();

function App() {
	useEffect(() => {
		if (enablePixel) {
			TagManager.initialize({"gtmId": "GTM-TFMMCQZV"})
			ReactPixel.init('596682932789796');
			ReactPixel.pageView();
		}
		if(clarityId) {
			clarity.init(clarityId);
		}
	}, []);

		const { isMobile } = useIsResponsive();

		return <BrowserRouter>
				<GoogleOAuthProvider clientId={clientId}>
					<SiderContextProvider>
						<AppProviders>
							<CustomErrorBoundary>
								<CriticalStyles isMobile={isMobile}/>
								<ApplicationRoutes/>
								<ReactQueryDevtools/>
							</CustomErrorBoundary>
						</AppProviders>
					</SiderContextProvider>
				</GoogleOAuthProvider>
		</BrowserRouter>;
}

export default App;

