import React, {FC} from "react";
import {InfoCircleOutlined} from "@ant-design/icons";
import {Skeleton} from "antd";
import classNames from "classnames";

import {UpcomingSlotsBannerStyled} from "./upcoming-slots-banner.styled";
import {UpcomingSlot} from "../upcoming-slot/upcoming-slot";
import {TUpcomingSlot, TWeekDay} from "../../../../../types";
import {TType} from "../../../../../assets/translations";
import {EViews, TDaysDisplayRange} from "../../lesson-picker";

interface Props {
  t: TType;
  upcomingSlots: TUpcomingSlot[];
  activeSlots?: string[];
  setActiveSlots?: React.Dispatch<React.SetStateAction<string[]>>;
  setDaysDisplayRange: React.Dispatch<React.SetStateAction<TDaysDisplayRange>>;
  loading?: boolean;
  view: EViews;
  isMobile: boolean;
  daysData: TWeekDay[];
  setActiveSlotsLimitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  goToConnectRequestPage?: boolean;
  teacherId?: string;
  roleTeacher: boolean;
  isPreview?: boolean;
}

export const UpcomingSlotsBanner: FC<Props> = ({
                                                 t,
                                                 goToConnectRequestPage,
                                                 teacherId,
                                                 upcomingSlots,
                                                 setActiveSlotsLimitModalOpen,
                                                 daysData,
                                                 isMobile,
                                                  roleTeacher,
                                                 view,
                                                 activeSlots,
                                                 setActiveSlots,
                                                 setDaysDisplayRange,
                                                 loading,
                                                 isPreview
                                               }) => {

  return (
    <UpcomingSlotsBannerStyled>
      {!loading && <div className={classNames("content-container", {
        "upcoming-slots-unavailable": !upcomingSlots.length,
      })} style={{ pointerEvents: isPreview ? "none" : "auto"}}>
          <div className="banner-info">
              <InfoCircleOutlined/>
            {upcomingSlots.length ? t("user-details.available-slots.text") : t("user-details.no-available-slots.text")}
          </div>
        {!!upcomingSlots.length && <div className="upcoming-slots-container">
          {upcomingSlots && upcomingSlots.map((slot) => <UpcomingSlot key={slot.startDate} teacherId={teacherId}
                                                                      roleTeacher={roleTeacher}
                                                                      goToConnectRequestPage={goToConnectRequestPage}
                                                                      setActiveSlotsLimitModalOpen={setActiveSlotsLimitModalOpen}
                                                                      daysData={daysData} isMobile={isMobile}
                                                                      view={view}
                                                                      setDaysDisplayRange={setDaysDisplayRange}
                                                                      upcomingSlotData={slot} activeSlots={activeSlots}
                                                                      setActiveSlots={setActiveSlots}/>)}
        </div>}
      </div>}

      <Skeleton active loading={loading} paragraph={{rows: 1, width: '100%'}} title={false}/>
    </UpcomingSlotsBannerStyled>
  )
};