import { useCallback, useEffect, useState } from "react";

import debounce from "lodash.debounce";

export const useDebounce = <T,>(debounceTime: number, initialValue: T) => {
  const [debounceValue, setDebounceValue] = useState<T>(initialValue);

  const performSearch = (value: T) => {
    setDebounceValue(value);
  }

  const performSearchDebounce = useCallback(
    debounce((value: T) => {
      setDebounceValue(value);
    }, debounceTime),
    []
  );

  useEffect(() => {
    return () => {
        performSearchDebounce.cancel();
    };
  }, [performSearchDebounce]);

  return { debounceValue, performSearchDebounce, performSearch };
};
