import { FC } from "react";

import { createSearchParams, useNavigate } from "react-router-dom";

import { TType } from "../../../assets/translations";

import { ButtonTheme, StyledButton } from "../../elements/button";
import { ClarificationNeededPopupWrapper } from "../clarification-needed/clarification-needed-popup-styled";

interface Props {
  t: TType;
  open: boolean;
  onCancel: () => void;
}

const WrongCategoryPopup: FC<Props> = ({ t, open, onCancel }) => {
  const navigate = useNavigate();
  return (
    <ClarificationNeededPopupWrapper open={open} footer={null} closable={false}>
      <h1>{t("lesson-requests.modals.unable-to-respond")}</h1>
      <p>{t("lesson-requests.modals.unable-to-respond-desc")}</p>
      <div className="buttons">
        <StyledButton theme={ButtonTheme.WHITE} onClick={onCancel}>
          {t("lesson-requests.modals.back")}
        </StyledButton>
        <StyledButton
          theme={ButtonTheme.BLUE}
          onClick={() =>
            navigate({
              pathname: `/lesson-requests/opportunity-spot`,
              search: createSearchParams({ filter: "MY_SUBJECTS" }).toString(),
            })
          }
        >
          {t("lesson-requests.modals.show-mine-offers")}
        </StyledButton>
      </div>
    </ClarificationNeededPopupWrapper>
  );
};

export default WrongCategoryPopup;
