import styled from "styled-components";
import {
  StudentDropdownCardMessage,
  StudentDropdownCardPrice,
  StudentDropdownCardStatus,
  StudentDropdownCardUserInfo,
  StudentDropdownCardWrapper,
} from "../student-dropdown/student-dropdown-card/student-dropdown-card-styled";
import { device } from "../../../../constants";

export const TeacherDropdownCardWrapper = styled(StudentDropdownCardWrapper)`
  @media (min-width: 1281px) {
    grid-template-columns: 1fr 1fr 1.5fr 4fr;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 12px 0px;
    border-top: 1px solid rgb(214, 228, 255);
  }
`;

export const TeacherDropdownCardUserInfo = styled(StudentDropdownCardUserInfo)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
  }
`;

export const TeacherDropdownCardPrice = styled(StudentDropdownCardPrice)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
  }
`;

export const TeacherDropdownCardStatus = styled(StudentDropdownCardStatus)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
  }
`;


export const TeacherDropdownCardMessage = styled(StudentDropdownCardMessage)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 100%;
  }
`