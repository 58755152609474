import { Translations } from "../../../../assets/translations";

import {UseGetConnectionRequestsByTypePayloadProps } from "../../../../hooks/useGetConnectionRequests";

import { UnionTabsType } from "../lesson-request";

import {
  GetRequestsLessonStatuses,
  LessonRequestType,
} from "../../../../types";
import { TeacherFilterOptions } from "../lesson-request-filters/lesson-request-filters";

interface PayloadProps {
  selectedKey: UnionTabsType;
  isStudent: boolean;
  language: Translations;
  isSwitchActive: boolean;
  currentPage: number;
  filterParam: TeacherFilterOptions;
}

export const getPayload = ({
  isStudent,
  isSwitchActive,
  language,
  selectedKey,
  currentPage,
  filterParam
}: PayloadProps): UseGetConnectionRequestsByTypePayloadProps => {
  switch (selectedKey) {
    case "requests_from_students":
      return {
        enabled: true,
        isStudent,
        language,
        status: isSwitchActive ? GetRequestsLessonStatuses.CREATED : null,
        type: LessonRequestType.STUDENT_DIRECT,
        page: currentPage - 1,
        queryKey: ["teacherDirectRequests", isSwitchActive, language, currentPage],
      };

    case "opportunity_spot":
      return {
        enabled: true,
        isStudent,
        language,
        status: isSwitchActive ? GetRequestsLessonStatuses.CREATED : null,
        type: LessonRequestType.STUDENT_PUBLIC,
        page: currentPage - 1,
        queryKey: ["teacherPublicRequests", isSwitchActive, language, currentPage],
        alreadyAppliedRequests: filterParam === TeacherFilterOptions.RESPONDED ? true : false
      };

    case "active_requests":
      return {
        enabled: true,
        isStudent,
        language,
        status: isSwitchActive ? GetRequestsLessonStatuses.CREATED : null,
        type: null,
        page: currentPage - 1,
        queryKey: ["studentActiveRequests", isSwitchActive, language, currentPage],
      };
      
    default:
      return {
        enabled: false,
        isStudent,
        language,
        status: null,
        type: LessonRequestType.STUDENT_DIRECT,
        queryKey: ["defaultKey"],
      };
  }
};
