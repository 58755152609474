import {Dispatch, SetStateAction} from "react";
import {RcFile} from "antd/lib/upload/interface";

import {EVerificationStatus, LessonType, TLanguageLevel} from "./Categories";


export type TObject<T = any> = {
		[key: string]: T
};

export type setState<T> = Dispatch<SetStateAction<T>>

export enum Authority {
		ROLE_STUDENT = "ROLE_STUDENT",
		ROLE_TEACHER = "ROLE_TEACHER",
		ROLE_ADMINISTRATOR = "ROLE_ADMINISTRATOR"
}

export enum Gender {
		MALE = "MALE",
		FEMALE = "FEMALE"
}

export type TUserProfileSetupResponse = {
	data: TUserProfileSetup
};


export type TUserProfileSetup = {
	mainDataFilled: boolean;
	otherInfoFilled: boolean;
	userInVerificationStatus: EVerificationStatus;
	userInVerificationStatusDesc: boolean;
	scheduleFilled: boolean;
};

export type TUserProfileResponse = {
	data: TUserProfileData;
};

export type TUserProfileData = {
	id: string;
	firstName: string;
	lastName: string | null;
	middleName: string | null;
	phoneNumber: string;
	avatarId: string | null;
	countryCode: string | null;
	cityCode: string | null;
	about: string | null;
	email: string;
	gender: Gender;
	notificationsEnabled: boolean;
	verified: boolean;
	clarificationNeeded: boolean;
	showStartingTour: boolean;
	lastActiveTime: string;
	registrationStep: RegistrationStep | null;
	connectionRequestsCounter: number;
}

export interface IUserProfileInfoRequest {
		firstName: string;
		lastName: string;
		middleName: string;
		gender: Gender;
		countryCode: string;
		phoneNumber: string;
		isRegistration?: boolean;
		phoneNumberConfirmed?: boolean;
		notificationsEnabled?: boolean;
		skills?: TRegistrationSkills[];
		registrationStep: ERegistrationStep | null;
}

export interface IUpdateStudentProfile {
		firstName: string;
		lastName: string;
		avatarId?: string;
		city?: number;
		country?: number;
		about?: string;
		cityCode?: null;
		registrationStep: ERegistrationStep | null;
        completeRegistration:true;
}

export interface IEducationExperienceForUpdate {
		id?: string;
		title: string;
		fromYear: string | null;
		toYear: string | null;
		attachments: string[];
}

export type TUpdateTeacherBasicInfo = {
		avatarId?: string;
		firstName: string;
		lastName: string;
		city:number;
		title?: string;
		aboutMe?: string;
		videoId: string | null;
		dateOfBirth: string | null;
		coverLetter: string | null;
		educations: IEducationExperienceForUpdate[];
		experiences: IEducationExperienceForUpdate[];
		videoPreviewImageId: string | null;
}

export type TUpdateTeacherOtherInfo = {
		languages: {
				level: TLanguageLevel;
				languageId: string;
		}[];
		availableFrom: string;
		availableTo: string;
		lessonType?: LessonType;
}

export type TUserProfilePhotoResponse = {
		data: {
				photoId: string;
		}
};

export type TCompleteUserRegistrationRequest = {
		notificationsEnabled?: boolean;
		skills: TRegistrationSkills[];
};

export type TRegistrationSkills = {
		id: string;
		pricePerHour: number;
		currencyId: number;
}

export type TUserAuthenticationResponse = {
		data: {
				email: string;
				refreshToken: string;
				token: string;
				registrationStep: RegistrationStep | null;
				role: string;
		}
};

export type TTokenUpdateBody = {
		token?: string;
		refreshToken?: string;
		jwtParsed?: TJwtTokenEncoded;
}

export type TSendVerificationCodeRequest = {
		countryCode: string;
		phoneNumber: string;
		verificationCode: number;
		registration?: boolean;
};

export type TValidateEmailRequest = {
		email: string | null;
		code: number;
};

export type TValidateEmailResponse = {
		data: {
				emailAlreadyInUse: boolean;
				validEmail: boolean;
		}
		status: number;
};

export enum RegistrationStep {
		PROFILE_INFO = 0,
		PHONE_NUMBER = 1,
		SKILLS = 2,
		COMPLETE = 3
}

export enum ERegistrationStep {
		PROFILE_INFO = "PROFILE_INFO",
		PHONE_NUMBER = "PHONE_NUMBER",
		SKILLS = "SKILLS",
		COMPLETE = "COMPLETE"
}

export type TResetPasswordMailRequest = {
		email: string;
}

export type TResetPasswordRequest = {
		password: string;
		confirmPassword: string;
		hash: string;
}

export enum NotificationType {
		TRANSACTION_NOTIFICATION = "TRANSACTION_NOTIFICATION",
		LESSON_NOTIFICATION = "LESSON_NOTIFICATION",
		SCHEDULE_NOTIFICATION = "SCHEDULE_NOTIFICATION",
		INFORMATION_NOTIFICATION = "INFORMATION_NOTIFICATION"
}

export type TNotificationsResponse = {
		data: {
				notifications: Notification[],
				isLast: boolean,
				page: number,
				pageSize: number,
				unreadNotifications: number
		};
}

export type Notification = {
		id: number,
		description: string,
		title: string,
		type: NotificationType,
		checked: boolean,
		actionLink: string,
		actionTitle: string,
		notificationLink: string,
		createdDate: string;
}

export type TUpdateUserEmail = {
		newEmail: string;
		code: number;
}

export type TJwtTokenEncoded = {
		sub?: string;
		user_id?: string;
		exp?: number;
		iat?: number;
		authority?: Authority;
}

export type TPhoneValidationResponse = {
		data: IPhoneValidation
};

export interface IPhoneValidation {
		verificationCodeNotValid: boolean,
		phoneNumberNotFound: boolean,
		phoneNumberUnique: boolean
}

export interface IValidatePhoneRequest {
		phoneNumber: string,
		countryCode: string,
		verificationCode?: number,
		registration?: boolean
}

export type TFile = {
		id: string;
		lessonId?: string;
		filename: string;
		contentType: string;
		fileSize: number;
		originFileObj?: RcFile;
		attachmentType: AttachmentType;
		lessonAttachmentType: LessonAttachmentType;
		link?: string;
		createdDate?: Date | string;
		createdUser?: string;
}

export type TAttachment = {
		id: string;
		type: LessonAttachmentType;
}

export type TVideoUploadResponse = {
		data: {
				id: string;
		}
}

export enum AttachmentType {
		MEDIA = "MEDIA",
		LINK = "LINK",
		FILE = "FILE",
}

export enum LessonAttachmentType {
	TASK = "TASK",
	SOLUTION = "SOLUTION",
	VERIFICATION = "VERIFICATION",
	LESSON = "LESSON"
}

export type TFeedbacksResponse = {
	data: {
		feedbacks: TFeedbackBody[];
		pageSize: number;
		totalItems: number;
	}
}

export type TFeedbackBody = {
		id: string;
		type: FeedbackType;
		email: string;
		rating?: number;
		comment?: string;
		requestedCategory?: string;
		medias?: TFile[];
}

export enum FeedbackType {
		CATEGORY_REQUEST = "CATEGORY_REQUEST",
		FEATURE_REQUEST = "FEATURE_REQUEST",
		GENERAL = "GENERAL",
}

export type TRefreshTokenBody = {
		refreshToken: string;
}

export interface IRefreshTokenResponse {
		data: TRefreshToken;
}

export type TRefreshToken = {
		token: string;
}

export enum AIHelpType {
	COMPLETE_TEACHER_DESCRIPTION = "COMPLETE_TEACHER_DESCRIPTION",
	ADJUST_TEACHER_DESCRIPTION = "ADJUST_TEACHER_DESCRIPTION",
	TEACHER_DESCRIPTION_EXAMPLE = "TEACHER_DESCRIPTION_EXAMPLE",
}

export type TUserDataForAIGenerate = {
	promptTemplateCode: AIHelpType;
	existingDescription: string;
	title: string;
	subjectNames: string[];
}

export type TAIGenerateUserDescriptionResponse = {
	data: {
		generatedText: string;
		usedTokens: number;
	},
};

export type TUserAIQuotaResponse = {
	data: TUserAIQuota
};

export type TUserAIQuota = {
	quotaAmount: number;
	quotaUsed: number;
}

export enum EScheduleSlotType {
	AVAILABLE = "AVAILABLE",
	INDIVIDUAL_LESSON = "INDIVIDUAL_LESSON"
}

export enum EManualScheduleSlotType {
	NOT_AVAILABLE = "NOT_AVAILABLE",
	ALL_TIME_BOOKED = "ALL_TIME_BOOKED"
}

export type TScheduleSlot = {
	availableSeatsQuantity: number;
	endDate: string;
	startDate: string;
	totalSeatsQuantity: number;
	type : EScheduleSlotType
}

export type TExtendedScheduleSlot = TScheduleSlot & {
	startDateDisplayFormat: string;
}

export type TLessonScheduleResponse = {
 data : {
	 hasAvailableTimeslots: boolean;
	 nextAvailableTimeSlots: string[];
	 timeSlots: TScheduleSlot[]
 }
}

export type TWeekDay = {
	dayName: string;
	dayNumber: string;
	date: string;
	slots: TExtendedScheduleSlot[];
}

export type TUpcomingSlot = {
	startDate: string;
	startDateDisplayFormat: string;
}

export type TInviteStudentBody = {
	inviteeEmail: string;
}

export enum ETeacherStudentMappingStatus {
	INVITED="INVITED",
	ACTIVE= "ACTIVE",
	INACTIVE = "INACTIVE"
}

export type TTeacherStudentMapping = {
	teacherFirstName: string,
	teacherLastName: string,
	teacherId: string,
	teacherAvatarId: string,
	studentFirstName: string,
	studentLastName: string,
	studentId: string,
	studentAvatarId: string,
	individualLessonPrice: number,
	status: ETeacherStudentMappingStatus,
}

export type TUsersResponse = {
	data: {
		pageSize: number,
		totalItems: number,
		users: TUser[]
	}
}

export type TUser = {
	id: string,
	avatarId: string,
	fullName: string,
	userType: string,
	createdDate: string,
}