import axiosClient from "../../config/axiosConfig";

import { GET_CONNECTION_REQUESTS } from "../endpoints";

import { IGetConnectionReqeustsResponse, IGetConnectionRequests } from "../../types";

export const getConnectionRequests = async (payload: IGetConnectionRequests, languageCode: string): Promise<IGetConnectionReqeustsResponse> => {
  return axiosClient.get(GET_CONNECTION_REQUESTS(), {
    params: {
        type: payload.type,
        status: payload.status,
        categoryIds: payload.categoryIds,
        size: payload.size || null,
        page: payload.page || null,
        alreadyAppliedRequests: payload.alreadyAppliedRequests
    },
    paramsSerializer: {
      indexes: null,
    },
    headers: {
      "Accept-Language": languageCode
    }
  })
};