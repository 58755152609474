import { Book } from "../../../assets/images/svg-elements/book";
import { BookApple } from "../../../assets/images/svg-elements/book-apple";
import { Blackboard } from "../../../assets/images/svg-elements/blackboard";
import { SettingsIcon } from "../../../assets/images/svg-elements/settings";
import { StudyingHat } from "../../../assets/images/svg-elements/studying-hat";

import { ProfileSiderMenuItems } from "../../../types/ProfileSider";
import { TType } from "../../../assets/translations";
import { Authority } from "../../../types";
import { CommentOutlined, HeartOutlined, SolutionOutlined, SyncOutlined } from "@ant-design/icons";
import { GlassesIcon } from "../../../assets/images/svg-elements/glasses";
import { BellIcon } from "../../../assets/images/svg-elements/bell";
import { SmsIcon } from "../../../assets/images/svg-meeting";
import {HelpCircle} from "../../../assets/images/svg-elements/help-circle";
import { UserIcon } from "../../../assets/images/svg-elements/user";

export const getMainNavMenu = (
  t: TType,
  authority: Authority,
  lessonRequestCount?: number
): ProfileSiderMenuItems[] => [
  {
    id: 0,
    icon: <BookApple />,
    label: t("footer.navigation-menu.home"),
    key: "home",
    to: "/user/profile",
  },
  ...(authority === Authority.ROLE_STUDENT
    ? [
        {
          id: 1,
          icon: <HeartOutlined />,
          label: t("favourites.header.caption"),
          key: "favorites",
          to: "/favourites",
        },
      ]
    : []),
  ...(authority === Authority.ROLE_TEACHER
    ? [
        {
          id: 2,
          icon: <Blackboard />,
          label: t("schedule.header"),
          key: "schedule",
          to: "/schedule",
        },
        {
          id: 3,
          icon: <StudyingHat />,
          label: t("my-students.title"),
          key: "my-students",
          children: [
            {
              id: 4,
              label: t("my-students.tabs.all-students"),
              key: "my-students",
              to: "/my-students/all-students",
            },
            {
              id: 5,
              label: t("my-students.tabs.invited-students"),
              key: "my-students",
              to: "/my-students/invited-students",
            },
          ],
        },
      ]
    : []),
  {
    id: 6,
    icon: <Book />,
    label: t("lessons.header.title"),
    key: "lessons",
    to: "/lessons",
  },
  ...(authority === Authority.ROLE_TEACHER
    ? [
        {
          id: 7,
          icon: <GlassesIcon />,
          label: t("lesson-requests.title"),
          key: "lesson-requests",
          count: lessonRequestCount,
          children: [
            {
              id: 10,
              label: t("lesson-requests.tabs.requests-from-students"),
              key: "lesson-requests",
              count: lessonRequestCount,
              to: "/lesson-requests/requests-from-students",
            },
            {
              id: 11,
              label: t("lesson-requests.tabs.opportunity-spot"),
              key: "lesson-requests",
              to: "/lesson-requests/opportunity-spot?filter=MY_SUBJECTS",
            },
          ],
        },
      ]
    : [
        {
          id: 8,
          icon: <GlassesIcon />,
          label: t("lesson-requests.title"),
          key: "lesson-requests",
          count: lessonRequestCount,
          to: "/lesson-requests",
        },
      ]),
];

// TEMPLATE WITH CHILDREN

// {
//     id: 7,
//     icon: <OpenBook/>,
//     label: "Шаблони курсів",
//     key: "templates-courses",
//     children: [
//         {
//             id: 8,
//             label: "Шаблони курсів",
//             key: "templates-courses",
//             to: "#"
//         },
//         {
//             id: 9,
//             label: "Шаблони курсів",
//             key: "templates-courses",
//             to: "#"
//         },
//         {
//             id: 100,
//             label: "Шаблони курсів",
//             key: "templates-courses",
//             to: "#"
//         },
//     ]
// },

export const getOtherNavMenu = (
  t: TType,
  authority: Authority,
  isOnlyResponsive: boolean
): ProfileSiderMenuItems[] => {
  const menuItems: ProfileSiderMenuItems[] = [
    {
      id: 0,
      icon: <SettingsIcon className="settings-icon" />,
      label: t("settings"),
      key: "settings",
      children: [
        ...(authority === Authority.ROLE_STUDENT
          ? [
              {
                id: 1,
                label: t("profile.settings.account.header"),
                key: "settings",
                to: "/user/settings/account",
              },
              {
                id: 2,
                label: t("profile.settings.security.header"),
                key: "settings",
                to: "/user/settings/security",
              },
            ]
          : [
              {
                id: 1,
                label: t("profile.settings.account.header"),
                key: "settings",
                to: "/user/settings/account",
              },
              {
                id: 2,
                label: t("profile.settings.additional-info.header"),
                key: "settings",
                to: "/user/settings/price-schedule",
              },
              {
                id: 3,
                label: t("profile.settings.security.header"),
                key: "settings",
                to: "/user/settings/security",
              },
              {
                id: 4,
                label: t("profile.settings.lesson.header"),
                key: "settings",
                to: "/user/settings/lessons",
              },
            ]),
      ],
    },
  ];

  if (isOnlyResponsive) {
    menuItems.push(
      {
        id: 1,
        icon: <BellIcon />,
        key: "notifications",
        label: t("notifications.title"),
        to: "/notifications",
      },
      {
        id: 2,
        icon: <SmsIcon />,
        key: "messenger",
        label: t("messenger.title"),
        to: "/m",
      }
    );
  }

  return menuItems;
};

export const getAdminNavMenu = (): ProfileSiderMenuItems[] => [
  {
    id: 0,
    icon: <SolutionOutlined />,
    label: "Проблеми користувачів",
    key: "problems",
    to: "/administrator/support",
  },
  {
    id: 1,
    icon: <CommentOutlined />,
    label: "Відгуки користувачів",
    key: "feedbacks",
    to: "/administrator/feedbacks",
  },
  {
    id: 2,
    icon: <UserIcon />,
    label: "Користувачі",
    key: "users",
    to: "/administrator/users",
  },
  {
    id: 3,
    icon: <UserIcon />,
    label: "Верифікація користувачів",
    key: "verification",
    to: "/administrator/verification",
  }
];

export const getSupportMenuItem =(t: TType) : ProfileSiderMenuItems => {
  return ({
    id: 3,
    icon: <HelpCircle />,
    label: t("support.title"),
    key: "support",
    children: [
      {
        id: 4,
        label: t("support.title"),
        key: "support",
        to: "/support",
      },
      {
        id: 5,
        label: t("faq-page.header"),
        key: "support",
        to: "/faq",
      },
    ],
  })
}

export const getRecalculateButton = (label: JSX.Element): ProfileSiderMenuItems => ({
  id: 0,
  icon: <SyncOutlined />,
  label: label,
  key: "update",
})