export const UPDATE_USER_STATE = "UPDATE_USER_STATE";
export const REMOVE_USER_STATE = "REMOVE_USER_STATE";
export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";

export const UPDATE_REGISTRATION_STEP = "UPDATE_REGISTRATION_STEP"

export const SET_CATEGORIES = "SET_CATEGORIES";

export const SET_COUNTRIES = "SET_COUNTRIES";

export const SET_CITIES = "SET_CITIES";

export const SET_LANGUAGES = "SET_LANGUAGES";

export const SET_CURRENCIES = "SET_CURRENCIES";

export const SET_PRICE_RANGE = "SET_PRICE_RANGE";

export const SET_SEARCH_BUTTON_CLICKED = "SET_SEARCH_BUTTON_CLICKED";

export const UPDATE_SEARCH_CATEGORY = "UPDATE_SEARCH_CATEGORY";
export const RESET_SEARCH_CATEGORY = "RESET_SEARCH_CATEGORY";

export const UPDATE_SEARCH_CITY = "UPDATE_SEARCH_CITY";
export const RESET_SEARCH_CITY = "RESET_SEARCH_CITY";

export const SET_FORGOT_PASSWORD_EMAIL = "SET_FORGOT_PASSWORD_EMAIL";

export const SET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON = "SET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON";

export const RESET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON = "RESET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON";