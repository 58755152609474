import {FC, useContext, useEffect, useState} from "react";
import {CityFilterStyled} from "./city-filter.styled";
import {Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {getCities} from "../../../../../../api/user-api";
import {TranslationsContext} from "../../../../../../assets/translations/translationsContext";
import {CityData, setState} from "../../../../../../types";
import classNames from "classnames";
import {TType} from "../../../../../../assets/translations";
import {Filter, TagsRemoveMethodsBindings} from "../../search-input-filter.types";

interface Props {
  t: TType;
  filterData: Filter;
  setFilterData: setState<Filter>;
  setTags: setState<TagsRemoveMethodsBindings[]>;
  tagName?: string;
  selectedCity?: CityData | null;
  setSelectedCity: setState<CityData | null>;
  showCitiesList? :boolean;
}

export const CityFilter: FC<Props> = ({
                                        selectedCity,
                                        setSelectedCity,
                                        showCitiesList
                                      }) => {


  const {language} = useContext(TranslationsContext);

  const [searchTerm, setSearchTerm] = useState("");
  const [cities, setCities] = useState<CityData[]>([]);

  useEffect(() => {
    getCities(language, 1)
      .then(({data}) => {
        if (data.length) setCities(data);
      })
  }, []);

  function handleCityChange(city: CityData) {
    if (selectedCity?.code === city.code) {
      setSelectedCity(null);
      return
    }
    setSelectedCity(city);
  }

  return (
    <CityFilterStyled className="city-filter-container" style={!showCitiesList ? {display: 'none'}: undefined}>
      <h2>City</h2>
      <div className="search-input-wrapper">
        <Input className="search-input" prefix={<SearchOutlined/>} placeholder="Search City"
               onChange={(e) => setSearchTerm(e.target.value)}/>
      </div>
      <div className="cities-list-container">
        {cities.filter((city) => {
          if (searchTerm === "") {
            return city;
          } else if (city.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return city;
          }

        }).map((city) => {
          return (
            <div onClick={() => handleCityChange(city)} key={city.code} className={classNames({
              "city-container": true,
              "checked": selectedCity ? selectedCity.code === city.code : false
            })}>
              <input readOnly
                     type="radio"
                     checked={selectedCity ? selectedCity.code === city.code : false}
              />
              <span>{city.name}</span>
            </div>
          );
        })}
      </div>
    </CityFilterStyled>
  );
};