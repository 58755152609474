import styled from 'styled-components';
import {Divider} from "antd";

import {device} from "../../constants";
import {CalendarDayjs} from "../../elements-dayjs/calendar-dayjs";


export const ContentContainer = styled.div`
    .navigation, .content {
        width: 100%;
    }

    .schedule-header {
        h3 {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: rgb(24, 6, 125);
        }

        p {
            font-size: 16px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.45);
        }
    }

    .navigation {
        > button {
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
            border-radius: 8px;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            padding: 4px 15px;
            display: flex;
            align-items: center;
            column-gap: 12px;
            cursor: pointer;
            transition: box-shadow 0.3s ease-in-out;

            svg {
                height: 14px;
                width: 14px;
            }

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.16) 0 0 30px 0;
            }
        }
    }

    .content {
        display: flex;
        column-gap: 2%;

        .calendar {
            width: 32%;
            display: flex;
            flex-direction: column;
            row-gap: 24px;
        }

        .schedule {
            width: 66%;
            background: #FFFFFF;
            border-radius: 16px;
            padding: 24px;

            .fc-col-header,
            .fc-daygrid-body,
            .fc-scrollgrid-sync-table,
            .fc-timegrid-body, 
            .fc-timegrid-body table {
                width: 100% !important;
            }

            .fc-button {
                background-color: white;
                border: none;
                color: #292D32;
                height: auto;
                padding: 0;
                font-size: 20px;

                &:focus {
                    box-shadow: none;
                }

                &:active {
                    color: #5D51A4;
                }
            }

            &.full-calendar-prev-disabled {
                .fc-fullCalendarPrev-button {
                    color: #bfbfbf;
                    cursor: initial;

                    &:active {
                        color: #bfbfbf;
                    }
                }
            }

            &.full-calendar-next-disabled {
                .fc-fullCalendarNext-button {
                    color: #bfbfbf;
                    cursor: initial;

                    &:active {
                        color: #bfbfbf;
                    }
                }
            }
            
            &.prev-day-disabled {
                .fc-prevDay-button {
                    color: #bfbfbf;
                    cursor: initial;
                    pointer-events: none;

                    &:active {
                        color: #bfbfbf;
                    }
                }
                
            }
            
            &.next-day-disabled{
                .fc-nextDay-button{
                    color: #bfbfbf;
                    cursor: initial;
                    pointer-events: none;

                    &:active {
                        color: #bfbfbf;
                    }
                }
            }
            
            .fc .fc-timegrid-now-indicator-arrow {
                display: none;
            }

            .fc .fc-timegrid-now-indicator-line {
                border: dashed #5D51A4;
                border-width: 2px 0 0;
                display: flex;
                align-items: center;

                .indicator {
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 16px;
                    color: #FFFFFF;
                    background: #5D51A4;
                    padding: 1px 4px;
                    border-radius: 4px;
                    position: absolute;
                    top: 2px;
                    left: 2px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: -4px;
                    width: 2px;
                    height: 6px;
                    background: #5D51A4;
                }
            }

            .day {
                background: transparent;
            }

            .fc-highlight {
                background: #D1CDE5;
                opacity: 0.5;
            }

            .day_header:has(div[data-today=true]) {
                border-bottom: 1px solid #5D51A4;
            }

            .fc-v-event {
                background-color: transparent;
                border: none;

                &.fc-timegrid-event {
                    box-shadow: none;
                }
            }

            .fc-timeGridWeek-view {
                .fc-scrollgrid {
                    border: none;
                }


                thead tr {
                    th {
                        background: #FAFAFA;

                        &:first-child {
                            background: transparent;
                            border: none;
                        }
                    }
                }

                tbody {
                    .fc-timegrid-divider {
                        display: none !important;
                    }

                    .fc-scrollgrid-section:has(td.fc-timegrid-divider) {
                        display: none;
                    }

                    .fc-scrollgrid-section-body {
                        &:last-child {
                            > td {

                                border: none;
                            }
                        }

                        &:first-child {
                            display: none;
                        }
                    }
                }

                .fc-timegrid-event-harness-inset {
                    margin: -1px -4px 0 -3px;
                }

                .fc-timegrid-slots {
                    .fc-timegrid-slot {
                        height: 4.5em;
                    }

                    .fc-timegrid-slot-label {
                        border: none;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 16px;
                        color: #5D51A4;
                        vertical-align: baseline;

                        .fc-timegrid-slot-label-frame {
                            text-align: left;
                        }

                        .labelSlot {
                            > div {
                                position: relative;
                                top: -0.5rem;

                                &.gmt-time {
                                    top: 0;
                                }
                            }

                            &.past {
                                color: #8C8C8C;
                            }
                        }
                    }
                }

                .fc-scroller {

                    ::-webkit-scrollbar-track {
                        background: transparent;
                    }
                }
            }
        }
    }

    @media (${device.tabletMax}) {
        .content {
            flex-direction: column;
            align-items: center;
            background: #fff;
            padding: 16px 3% 32px;
            border-radius: 16px;

            .calendar {
                width: 100%;
            }

            .schedule {
                width: 100%;
                padding: 0;
            }
        }
    }

    @media (${device.mobileMax}) {
        .schedule-header {
            h2 {
                font-size: 20px;
            }

            p {
                font-size: 14px;
            }
        }


        .content {
            align-items: flex-start;

            .schedule {
                height: 1650px;


                .fc-header-toolbar {
                    align-items: start;

                    .fc-button-group {
                        gap: 16px;
                    }
                }

                > div {
                    height: 100%;

                    .fc-day-disabled {
                        display: none;
                    }
                }

            }
        }
    }

`

export const StyledDayHeaderContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  background: #FAFAFA;

  h4, p {
    margin: 0;
  }

  h4 {
    color: #8C8C8C;
  }

  p {
    font-weight: 500;
    color: #000000;

  }

  &.today {

    h4 {
      color: #5D51A4;
    }

    p {
      font-weight: 700;
      color: #5D51A4;
      background: #E8E6F2;
      border-radius: 50%;
      width: 24px;
      height: 24px;
    }
  }
`

export const StyledCalendar = styled(CalendarDayjs)`
    background: #FFFFFF;
    border-radius: 10px;
    padding: 24px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    @media (${device.tabletMax}) {
        padding: 0;
    }
    
    .custom-header {

        display: flex;
        justify-content: space-between;
        align-items: center;

        .custom-header-title {
            text-align: center;
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #1F1F1F;
            margin: 0;
        }

        .controls-block {

            display: flex;
            column-gap: 12px;

            button {
                font-size: 20px;
                padding: 0;
                cursor: pointer;
                border: none;
                background: none;
                
                &:active{
                    color: #5D51A4;
                }

                &:disabled {
                    color: #c3c3c3;
                    cursor: initial;
                }
            }

        }

    }

    .ant-picker-panel {
        border-radius: 10px;
        border: none;

        .ant-picker-content {
            thead tr th {
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #BFBFBF;
            }

            tbody tr td {
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #595959;

                .ant-picker-cell-inner {
                    margin: auto;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &.ant-picker-cell-disabled {
                    color: #ece4e4;

                    &:before {
                        background-color: transparent;
                    }
                }

                .ant-picker-calendar-date-today {
                    &.ant-picker-cell-inner:before {
                        border: 1px solid #5D51A4;
                        border-radius: 50%;
                    }

                    .ant-picker-calendar-date-value {
                        color: #5D51A4;
                    }
                }

                &.ant-picker-cell-selected {
                    .ant-picker-cell-inner {
                        color: #5D51A4;
                        background: #E8E6F2;
                        border-radius: 50%;
                    }
                }

                &.ant-picker-cell:hover {
                    .ant-picker-cell-inner {
                        border-radius: 50%;
                        color: #5D51A4;
                    }
                }
            }
        }
`

export const StyledDivider = styled(Divider)`
  margin: 24px 0;
`