import { Modal } from "antd";
import styled from "styled-components";

export const CardActionModalWrapper = styled(Modal)<{ isCreateLesson?: boolean}>`
  h1 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #000;
  }

  .info {
    background: rgb(250, 250, 250);
    border-radius: 4px;
    padding: 12px;

    p {
      color: rgba(0, 0, 0, 0.8);
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3em;
    }

  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    gap: ${({ isCreateLesson }) => isCreateLesson ? "10px" : "100px"}
  }
`;
