import {ChangeEvent, FC, useState} from "react";
import classNames from "classnames";
import {SearchOutlined} from "@ant-design/icons";

import {Hint} from "../../hint";
import {Filter, TagsRemoveMethodsBindings} from "../../../pages";
import {MobileSearchFilter} from "../../../pages/mobile";
import {AdminProfile, Authority, setState, StudentProfile, TUserProfileData} from "../../../../types";
import {Translations, TType} from "../../../../assets/translations";
import {StyledLogo, StyledMobileHeader, StyledOpenMenu, StyledUnderline} from "./mobile-header.styled";
import {LocaleAuthMenu} from "./mobile-header-menu";
import {AffixStyled} from "../../../layout-components/affix/affix-styled";
import ProfileSider from "../../profile-sider/profile-sider";
import BurgerMenuButton from "../../burger-menu/burger-menu";


interface Props {
  t: TType;
  avatarId: string;
  logOut: any;
  firstName: string;
  lastName: string;
  notificationsCount: number;
  setNotificationsCount: setState<number>;
  messagesCount: number;
  setMessagesCount: setState<number>;
  language: Translations;
  onLanguageChange: (value: string) => void;
  goHome: () => void;
  signIn: () => void;
  openProfilePage: () => void;
  authority: Authority;
  isAuthenticated: () => boolean;
  searchDrawerOpen: boolean;
  setSearchDrawerOpen: any;
  filterData: Filter;
  setFilterData: setState<Filter>;
  searchStatus: boolean;
  tags: TagsRemoveMethodsBindings[];
  setTags: setState<TagsRemoveMethodsBindings[]>;
  performSearch: () => void;
  clearAll: () => void;
  applySearchText: (event: ChangeEvent<HTMLInputElement>) => void;
  menuOpen: boolean;
  handleMenuOpen: () => void;
  user: TUserProfileData | StudentProfile | AdminProfile | null;
  navigationDisabled: boolean;
}

export const MobileHeaderComponent: FC<Props> = ({
                                                   t,
                                                   avatarId,
                                                   logOut,
                                                   firstName,
                                                   lastName,
                                                   notificationsCount,
                                                   messagesCount,
                                                   language,
                                                   onLanguageChange,
                                                   goHome,
                                                   openProfilePage,
                                                   authority,
                                                   isAuthenticated,
                                                   searchDrawerOpen,
                                                   setSearchDrawerOpen,
                                                   filterData,
                                                   setFilterData,
                                                   searchStatus,
                                                   tags,
                                                   setTags,
                                                   performSearch,
                                                   clearAll,
                                                   applySearchText,
                                                   menuOpen,
                                                   handleMenuOpen,
                                                   user,
                                                   navigationDisabled
                                                 }) => {

  const [localeAuthMenuOpen, setLocaleAuthMenuOpen] = useState<boolean>(false);

  const isTeacher = authority === Authority.ROLE_TEACHER;

  const openSearchDrawer = () => setSearchDrawerOpen(true);

  return <AffixStyled>
    <StyledMobileHeader>
      <Hint showHint={navigationDisabled}
            placement="bottomLeft"
            title={t("registration.disabled-header-hint")}>
        <div className="header-logo" data-cy="header-logo" onClick={() => !navigationDisabled && goHome()}>
          <StyledLogo/>
          <h4>{t("teaching-me")}
            <StyledUnderline/>
          </h4>
        </div>
      </Hint>

      <div className="search-button-and-menu-container">
        {!isTeacher &&
            <Hint showHint={navigationDisabled}
                  placement="bottom"
                  title={t("registration.disabled-header-hint")}>
                        <span>
                             <div data-cy="search-button-inside-mobile-header"
                                  className={classNames("search-button", {searchModeActive: searchStatus})}
                                  onClick={() => !navigationDisabled && openSearchDrawer()}>
                                <SearchOutlined/> {t("find-tutor")}
                            </div>
                         </span>
            </Hint>}

        {isAuthenticated() ?
          <BurgerMenuButton dataCy="open-close-sider-button" handleMenuOpen={handleMenuOpen} menuOpen={menuOpen}/>
          : <StyledOpenMenu data-cy="burger-menu" onClick={() => setLocaleAuthMenuOpen(true)}/>}

      </div>

      {isAuthenticated() && user ?
        <ProfileSider t={t} administrator={authority === Authority.ROLE_ADMINISTRATOR} isOpen={menuOpen}
                      setIsOpen={handleMenuOpen} user={user} navigationDisabled={navigationDisabled}
                      dataCy="profile-sider"/>
        :
        <LocaleAuthMenu language={language} onLanguageChange={onLanguageChange} t={t} open={localeAuthMenuOpen}
                        setIsOpen={setLocaleAuthMenuOpen}/>

      }

      {!isTeacher && <MobileSearchFilter t={t}
                                         setSearchDrawerOpen={setSearchDrawerOpen}
                                         searchDrawerOpen={searchDrawerOpen}
                                         tags={tags}
                                         setTags={setTags}
                                         clearAll={clearAll}
                                         performSearch={performSearch}
                                         filterData={filterData}
                                         setFilterData={setFilterData}
                                         applySearchText={applySearchText}
      />}
    </StyledMobileHeader>
  </AffixStyled>;
};