import {
  GetRequestsLessonStatuses,
  IGetConnectionRequests,
  LessonRequestType,
  TUsersSkill,
} from "../types";
import { useSelector } from "react-redux";
import { RootState } from "../components/redux";
import { getUsersSkills } from "../api/category-api";
import { Translations } from "../assets/translations";
import { useQuery } from "@tanstack/react-query";
import { getConnectionRequests } from "../api/lesson-api/getConnectionRequests";
import { TeacherFilterOptions } from "../components/pages/lesson-request-page/lesson-request-filters/lesson-request-filters";

export interface UseGetConnectionRequestsByTypePayloadProps {
  enabled?: boolean;
  status: GetRequestsLessonStatuses | null;
  language: Translations;
  isStudent: boolean;
  type: LessonRequestType | null;
  page?: number;
  queryKey: Array<string | number | boolean>;
  size?: number;
  alreadyAppliedRequests?: boolean;
}

interface UseGetConnectionRequestsByTypeProps {
  payload: UseGetConnectionRequestsByTypePayloadProps;
  filterParam?: TeacherFilterOptions | null;
}

export const useGetConnectionRequestsByType = ({
  payload,
  filterParam,
}: UseGetConnectionRequestsByTypeProps) => {
  const { isStudent, language, queryKey, status, type, page, size, alreadyAppliedRequests } =
    payload;

  const {
    jwtParsed: { user_id },
  } = useSelector((state: RootState) => state.tokensStore);

  const {
    data: userSkills,
    isLoading: isLoadingUserSkills,
    isError: isErrorUserSkills,
  } = useQuery<TUsersSkill[]>({
    queryKey: ["userSkills", user_id, language],
    queryFn: async () => {
      const response = await getUsersSkills(user_id, language);
      return response.data.userCategories;
    },
    enabled: Boolean(user_id),
  });

  const categoryIds = isStudent
    ? null
    : userSkills?.map((skill) => skill.category.id);

  const saveFilterParam =
    filterParam === null ? TeacherFilterOptions.MY_SUBJECTS : filterParam;

  const requestPayload: IGetConnectionRequests = {
    categoryIds:
      saveFilterParam === TeacherFilterOptions.ALL ? null : categoryIds,
    type,
    status,
    page,
    size,
    alreadyAppliedRequests
  };

  const {
    data,
    isLoading: isLoadingRequests,
    isError: isErrorRequests,
    refetch
  } = useQuery({
    queryKey,
    queryFn: async () => {
      const res = await getConnectionRequests(requestPayload, language);
      return res.data;
    },
    enabled: isStudent
      ? true
      : !isLoadingUserSkills && !isErrorUserSkills,
  });

  return {
    requests: data?.connectionRequests ?? [],
    isLoading: isLoadingUserSkills || isLoadingRequests,
    isError: isErrorUserSkills || isErrorRequests,
    totalItems: data?.totalElements ?? 0,
    categoryIds: categoryIds ?? [],
    refetch,
    openDirectRequestsCount: data?.openDirectRequestsCount ?? 0
  };
};
