import {
    CLEAR_LOGIN_INFO,
    FILL_LOGIN_INFO,
    HIDE_REVIEW_HOMEWORK_MODAL,
    HIDE_REVIEW_RESULT_MODAL,
    HIDE_UPLOAD_RESULT_MODAL,
    SHOW_REVIEW_HOMEWORK_MODAL,
    SHOW_REVIEW_RESULT_MODAL,
    SHOW_UPLOAD_RESULT_MODAL
} from "./modalConstants";


const initialState = {
    showUploadResultModal: false,
    taskForUploadModal: {},
    showReviewResultModal: false,
    taskForReview: {},
    showReviewHomeworkModal: false,
    callbackAfterLogin: null,
    loginText: "",
}

export function modalsStore(state = initialState, action) {
    const actions = {
        [FILL_LOGIN_INFO]: {
            ...state,
            loginText: action.loginText || state.loginText,
            callbackAfterLogin: action.callbackAfterLogin || state.callbackAfterLogin,
        },
        [CLEAR_LOGIN_INFO]: {
            ...state,
            callbackAfterLogin: null,
            loginText: ""
        },
        [SHOW_UPLOAD_RESULT_MODAL]: {
            ...state,
            showUploadResultModal: true,
            taskForUploadModal: action.taskForUploadModal || state.taskForUploadModal,
        },
        [HIDE_UPLOAD_RESULT_MODAL]: {
            ...state,
            showUploadResultModal: false,
            taskForUploadModal: {},
        },
        [SHOW_REVIEW_RESULT_MODAL]: {
            ...state,
            showReviewResultModal: true,
            taskForReview: action.taskForReview || state.taskForReview,
        },
        [HIDE_REVIEW_RESULT_MODAL]: {
            ...state,
            showReviewResultModal: false,
            taskForReview: {},
        },
        [SHOW_REVIEW_HOMEWORK_MODAL]: {
            ...state,
            showReviewHomeworkModal: true,
        },
        [HIDE_REVIEW_HOMEWORK_MODAL]: {
            ...state,
            showReviewHomeworkModal: false,
        }
    }


    return actions[action.type] || state
}