import styled from "styled-components";

import {device} from "../../constants";
import {CookieIcon} from "../../../assets/images/svg-elements/cookie";


export const Consent = styled.div<{ isSiderOpen: boolean, shouldRenderProfileSider: boolean }>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
  background: #fff;
  padding: 32px 80px;
  border-radius: 12px;
  box-shadow: 0 4px 127px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({isSiderOpen, shouldRenderProfileSider}) => shouldRenderProfileSider ? isSiderOpen ? "calc(100% - var(--sider-opened-width) - 20px)" : "calc(100% - var(--sider-collapsed-width) - 20px)" : "100%"};
  transition: width 0.3s ease-out;

  @media(${device.tabletMax}) {
    width: calc(100% - 20px);
  }

  @media(${device.desktop}) and (${device.smallDesktop}) {
    gap: 20px;
  }

  .text {
    display: flex;
    align-items: center;
    column-gap: 12px;

    h2 {
      margin: 0;
      color: #0E044B;
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
    }
  }

  .buttons {
    display: flex;
    column-gap: 24px;

    button {
      padding: 11px 24px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border-radius: 8px;
      cursor: pointer;
      border: none;
      transition: hover, 0.4s;

      &.decline {
        color: #0E044B;
        background: rgba(0, 0, 0, 0.07);

        &:hover {
          color: #fff;
          background: #0E044B;
        }
      }

      &.agree {
        color: #fff;
        border: none;
        background: #0E044B;

        &:hover {
          color: #0E044B;
          background: rgba(0, 0, 0, 0.07);
        }
      }
    }
  }

  @media (${device.tabletMax}) {
    padding: 32px 40px;
  }

  @media (${device.mobileMax}) {
    padding: 24px 16px;
    flex-direction: column;
    row-gap: 24px;

    .text {
      flex-direction: column;
      row-gap: 8px;

      h2 {
        text-align: center;
      }
    }

    .buttons {
      column-gap: 12px;
    }
  }
`

export const Cookie = styled(CookieIcon)`
  width: 24px;
  height: 24px;

  @media (${device.tabletMax}) {
    width: 32px;
    height: 32px;
  }

  @media (${device.mobileMax}) {
    width: 48px;
    height: 48px;
  }
`

export const CloseButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;  
    background-color: transparent;
    border: none;

    .icon {
      width: 16px;
      height: 16px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: black;
        transform: rotate(45deg);
      }

      &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 100%;
        background-color: black;
        transform: rotate(45deg);
      }
    }
`