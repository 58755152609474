import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { TType } from "../../../../assets/translations";

import InputSelect from "../../../elements/input-select/input-select";

interface SortOption {
  name: string;
  value: string;
}

interface FilterOption {
  name: string;
  value: TeacherFilterOptions;
}

export enum TeacherFilterOptions {
  MY_SUBJECTS = "MY_SUBJECTS",
  ALL = "ALL",
  RESPONDED = "RESPONDED",
}

const LessonRequestFilters = ({
  t,
  isSort,
  isFilter,
  goToPage,
}: {
  t: TType;
  isSort: boolean;
  isFilter: boolean;
  goToPage?: (value: number | string) => void
}) => {
  const sortOptions: SortOption[] = [
    {
      name: t("lesson-requests.sorts.new-to-old"),
      value: "asc",
    },
    {
      name: t("lesson-requests.sorts.old-to-new"),
      value: "desc",
    },
  ];

  const filterOptions: FilterOption[] = [
    {
      name: t("lesson-requests.filters.my-subjects"),
      value: TeacherFilterOptions.MY_SUBJECTS,
    },
    {
      name: t("lesson-requests.filters.all"),
      value: TeacherFilterOptions.ALL,
    },
    {
      name: t("lesson-requests.filters.responded"),
      value: TeacherFilterOptions.RESPONDED,
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const filterParam = searchParams.get("filter") as TeacherFilterOptions;

  const filterOption = filterOptions.find(option => option.value === filterParam)!;

  const [filterProps, setFilterProps] = useState({
    sort: sortOptions[0],
    filter: filterParam ? filterOption : filterOptions[0],
  });

  useEffect(() => {
    setFilterProps(prev => ({...prev, filter: filterOption}))
  }, [filterParam])

  const handleSortChange = (item: SortOption) => {
    searchParams.set("sort", item.value);
    setSearchParams(searchParams);
    setFilterProps((prev) => ({ ...prev, sort: item }));

    if (!goToPage) return
    goToPage(1);
  };
  
  const handleFilterChange = (item: FilterOption) => {
    searchParams.set("filter", item.value);
    setSearchParams(searchParams);
    setFilterProps((prev) => ({ ...prev, filter: item }));

    if (!goToPage) return
    goToPage(1);
  };
  
  return (
    <div>
      {isSort && (
        <InputSelect
          items={sortOptions}
          enableFilter={false}
          t={t}
          value={filterProps.sort}
          onChange={handleSortChange}
        />
      )}
      {isFilter && (
        <InputSelect
          items={filterOptions}
          enableFilter={false}
          t={t}
          value={filterProps.filter}
          onChange={handleFilterChange}
        />
      )}
    </div>
  );
};

export default LessonRequestFilters;