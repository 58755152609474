import {FC} from "react";
import {LocaleAuthMenuStyled} from "./locale-auth-menu.styled";
import {Translations, TType} from "../../../../../assets/translations";
import {useNavigate} from "react-router-dom";
import {UserOutlined} from "@ant-design/icons";
import {LanguageSwitch} from "../../../language-switch";

interface Props {
  t: TType;
  language: Translations;
  onLanguageChange: (value: string) => void;
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LocaleAuthMenu: FC<Props> = ({t, open, setIsOpen, language, onLanguageChange}) => {

  const navigate = useNavigate();

  function handleSignIn() {
    navigate("/login");
    setIsOpen(false);
  }

  function handleSignUp() {
    navigate("/signup-choose-role");
    setIsOpen(false);
  }

  return (
    <LocaleAuthMenuStyled height="330px" placement="top" open={open} onClose={() => setIsOpen(false)}>
      <div className="not-authorized-buttons-wrapper">
        <button className="login" onClick={handleSignIn} data-cy="login-btn">
          <UserOutlined/>
          {t("login.form.button.signin")}
        </button>
        <button className="create-account" data-cy="sign-up-header-btn" onClick={handleSignUp}>
          {t("register.createAccount")}
        </button>
      </div>
      <div className="language-switch-container">
        <LanguageSwitch className="language-switch" t={t} language={language} onLanguageChange={onLanguageChange}/>
      </div>
    </LocaleAuthMenuStyled>
  );
};