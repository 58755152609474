import { FC } from "react";
import { CardActionModalWrapper } from "./card-action-modal-styled";
import { ActionButtons } from "../../pages/lesson-request-page/lesson-request-action-buttons/lesson-request-action-buttons-styled";
import { ButtonTheme } from "../../elements/button";
import { TType } from "../../../assets/translations";

interface Props {
  open: boolean;
  header: string;
  paragraph?: string;
  handleRequest: () => void;
  handleCancel: () => void;
  handleRequestCreateLesson?: () => void;
  isCreateLesson?: boolean;
  t: TType;
}

const CardActionModal: FC<Props> = ({
  open,
  header,
  paragraph,
  handleRequest,
  handleRequestCreateLesson,
  handleCancel,
  isCreateLesson,
  t,
}) => {
  return (
    <CardActionModalWrapper
      open={open}
      footer={null}
      closable={false}
      isCreateLesson={isCreateLesson}
    >
      <h1>{header}</h1>
      {paragraph && <div className="info">
        <p>{paragraph}</p>
      </div>}
      <div className="buttons">
        <ActionButtons theme={ButtonTheme.WHITE} onClick={handleCancel}>
          {t("lesson-requests.modals.no")}
        </ActionButtons>
        <ActionButtons theme={ButtonTheme.BLUE} onClick={handleRequest}>
          {t("lesson-requests.modals.yes")}
        </ActionButtons>
        {isCreateLesson && (
          <ActionButtons
            theme={ButtonTheme.BLUE}
            onClick={handleRequestCreateLesson}
          >
            {t("lesson-requests.modals.yes-create")}
          </ActionButtons>
        )}
      </div>
    </CardActionModalWrapper>
  );
};

export default CardActionModal;
