import {
    REMOVE_USER_STATE, RESET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON,
    RESET_SEARCH_CATEGORY,
    SET_CATEGORIES,
    SET_COUNTRIES,
    SET_CITIES,
    SET_CURRENCIES,
    SET_FORGOT_PASSWORD_EMAIL,
    SET_LANGUAGES, SET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON, SET_PRICE_RANGE,
    SET_SEARCH_BUTTON_CLICKED, UPDATE_REGISTRATION_STEP,
    UPDATE_SEARCH_CATEGORY,
    UPDATE_USER_EMAIL,
    UPDATE_USER_STATE,
    UPDATE_SEARCH_CITY,
    RESET_SEARCH_CITY
} from "./userConstants";


const initialState = {
    email: "",
    avatarId: "",
    firstName: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    notificationsEnabled: false,
    gender: "",
    searchCategoryCode: null,
    searchCityCode: null,
    categories: [],
    countries: [],
    cities: [],
    languages: [],
    currencies: [],
    priceRange: null,
    searchButtonClicked: false,
    registrationStep: null,
    forgotPasswordEmail: "",
    prevLessonDetailsForNextLesson:null,
    verified: null
}

export function userStore(state = initialState, action) {

    const actions = {
        [UPDATE_USER_STATE]: {
            ...state,
            email: action.data?.email,
            avatarId: action.data?.avatarId,
            firstName: action.data?.firstName,
            lastName: action.data?.lastName,
            middleName: action.data?.middleName,
            phoneNumber: action.data?.phoneNumber,
            notificationsEnabled: action.data?.notificationsEnabled,
            gender: action.data?.gender,
            registrationStep: action.data?.registrationStep,
            verified: action.data?.verified
        },
        [UPDATE_REGISTRATION_STEP]:{
            ...state,
            registrationStep: action.registrationStep,
        },
        [UPDATE_USER_EMAIL]: {
            ...state,
            email: action.email
        },
        [UPDATE_SEARCH_CATEGORY]: {
            ...state,
            searchCategoryCode: action.code
        },
        [RESET_SEARCH_CATEGORY]: {
            ...state,
            searchCategoryCode: null
        },
        [UPDATE_SEARCH_CITY]: {
            ...state,
            searchCityCode: action.code
        },
        [RESET_SEARCH_CITY]: {
            ...state,
            searchCityCode: null
        },
        [SET_CATEGORIES]: {
            ...state,
            categories: action.categories
        },
        [SET_COUNTRIES]: {
            ...state,
            countries: action.countries
        },
        [SET_CITIES]: {
            ...state,
            cities: action.cities
        },
        [SET_LANGUAGES]: {
            ...state,
            languages: action.languages
        },
        [SET_CURRENCIES]: {
            ...state,
            currencies: action.currencies
        },
        [SET_PRICE_RANGE]:{
            ...state,
            priceRange: action.priceRange
        },
        [SET_SEARCH_BUTTON_CLICKED]: {
            ...state,
            searchButtonClicked: action.searchButtonClicked
        }, [SET_FORGOT_PASSWORD_EMAIL]: {
            ...state,
            forgotPasswordEmail: action.email
        },
        [REMOVE_USER_STATE]: {
            ...state,
            email: "",
            avatarId: "",
            firstName: "",
            lastName: "",
            middleName: "",
            phoneNumber: "",
            notificationsEnabled: false,
            gender: "",
            searchCategoryCode: null,
            registrationStep: null
        },
        [SET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON]:{
            ...state,
            prevLessonDetailsForNextLesson: action.payload?action.payload?.lessonDetails:null
        },
        [RESET_PREV_LESSON_DETAILS_FOR_NEXT_LESSON]:{
            ...state,
            prevLessonDetailsForNextLesson:null
        },
    }

    return actions[action.type] || state
}