import {connect} from "react-redux";

import {TeachingMeHeaderComponent} from "./header-element";
import {RootState} from "../../redux";
import {getToken} from "../../../api/tokenHeaderProvider";
import {Authority} from "../../../types";


const mapStateToProps = (state: RootState) => {
    return {
        avatarId: state.userStore.avatarId,
        accessToken: getToken(),
        firstName: state.userStore.firstName,
        lastName: state.userStore.lastName,
        isTeacher: state.tokensStore.jwtParsed.authority === Authority.ROLE_TEACHER,
        isShowChooseRole: state.modalsStore.showChooseRoleModal,
    }
}

export const TeachingMeHeader = connect(mapStateToProps)(TeachingMeHeaderComponent);