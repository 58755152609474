import { useState } from "react";
import {
  TeachingMeErrorNotification,
  TeachingMeSuccessNotification,
} from "../components/elements/notification";
import { PostRecalculateIndex } from "../api/category-api/postRecalculateIndex";

export const useRecalculateIndex = () => {
  const [cooldown, setCooldown] = useState(0);

  function recalculateIndex() {
    if (cooldown > 0) {
      TeachingMeErrorNotification(
        "Не можна оновити дані зараз. Дочекайтесь завершення таймеру."
      );
      return;
    }

    PostRecalculateIndex().then((response) => {
      TeachingMeSuccessNotification("Перерахунок відбувся успішно!");
      startCooldown();
    });
  }

  function startCooldown() {
    setCooldown(180);

    const interval = setInterval(() => {
      setCooldown((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          return 0;
        }

        return prev - 1;
      });
    }, 1000);
  }

  return { recalculateIndex, cooldown }
};
