import {
	REMOVE_USER_STATE,
	SET_FORGOT_PASSWORD_EMAIL,
	UPDATE_REGISTRATION_STEP,
	UPDATE_USER_EMAIL,
	UPDATE_USER_STATE
} from "./userConstants";
import {RegistrationStep, TUserProfileResponse} from "../../../../types";


export function updateUserInfo(userInfo: TUserProfileResponse) {
		return {
				type: UPDATE_USER_STATE,
				data: userInfo.data,
		};
}

export function removeUserInfo() {
		return {
				type: REMOVE_USER_STATE,
		};
}

export function updateUserRegistrationStep(registrationStep: RegistrationStep) {
	return {
		type: UPDATE_REGISTRATION_STEP,
		registrationStep
	}
}

export function updateUserEmail(email: string = "") {
		return {
				type: UPDATE_USER_EMAIL,
				email,
		};
}

export function setForgotPasswordEmail(email: string) {
		return {
				type: SET_FORGOT_PASSWORD_EMAIL,
				email,
		};
}