import {FC, memo} from "react";
import {UserOutlined} from "@ant-design/icons";
import classNames from "classnames";

import {NotificationsDropdown} from "../desktop-header/notifications-dropdown";
import {AdminProfile, Authority, StudentProfile, TUserProfileData} from "../../../../types";
import {Translations, TType} from "../../../../assets/translations";
import {StyledLogo, StyledTabletHeader} from "./tablet-header.styled";
import {Underline} from "../../../../assets/images/svg-elements/underline";
import {AffixStyled} from "../../../layout-components/affix/affix-styled";
import ProfileSider from "../../profile-sider/profile-sider";
import BurgerMenuButton from "../../burger-menu/burger-menu";
import {Hint} from "../../hint";


interface Props {
  t: TType;
  avatarId: string;
  logOut: () => void;
  firstName: string;
  lastName: string;
  notificationsCount: number;
  setNotificationsCount: any;
  messagesCount: number;
  setMessagesCount: any;
  language: Translations;
  onLanguageChange: (value: string) => void;
  goHome: () => void;
  signIn: () => void;
  signUp: () => void;
  openProfilePage: () => void;
  authority: Authority;
  isAuthenticated: () => boolean;
  menuOpen: boolean;
  handleMenuOpen: () => void;
  user: TUserProfileData | StudentProfile | AdminProfile | null;
  navigationDisabled: boolean;
}

export const TabletHeader: FC<Props> = memo(({
                                               t,
                                               notificationsCount,
                                               setNotificationsCount,
                                               messagesCount,
                                               setMessagesCount,
                                               goHome,
                                               signIn,
                                               signUp,
                                               authority,
                                               isAuthenticated,
                                               menuOpen,
                                               handleMenuOpen,
                                               user,
                                               navigationDisabled
                                             }) => {

  return <AffixStyled>
    <StyledTabletHeader>
      <div style={{position: "absolute"}}>
      </div>
      <Hint showHint={navigationDisabled}
            placement="bottomLeft"
            title={t("registration.disabled-header-hint")}
      >
        <div className="header-logo" data-cy="header-logo" onClick={() => !navigationDisabled && goHome()}>
          <StyledLogo/>
          <h4>{t("teaching-me")}
            <Underline/>
          </h4>
        </div>
      </Hint>

      {isAuthenticated() && user ?
        <div className="menu">
          <Hint showHint={navigationDisabled}
                placement="bottom"
                title={t("registration.disabled-header-hint")}
          >
            <div className={classNames("notifications-dropdown-wrapper", {"navigation-disabled": navigationDisabled})}>
              <NotificationsDropdown t={t}
                                     notificationsCount={notificationsCount}
                                     setNotificationsCount={setNotificationsCount}
                                     messagesCount={messagesCount}
                                     setMessagesCount={setMessagesCount}
              />
            </div>
          </Hint>
          <BurgerMenuButton handleMenuOpen={handleMenuOpen} menuOpen={menuOpen}/>
          <ProfileSider t={t} administrator={authority === Authority.ROLE_ADMINISTRATOR} isOpen={menuOpen}
                        setIsOpen={handleMenuOpen} user={user} navigationDisabled={navigationDisabled}/>

        </div>
        :
        <>
          <div className="not-authorized-buttons-wrapper">
            <button className="login" onClick={signIn} data-cy="login-btn">
              <UserOutlined/>
              {t("login.form.button.signin")}
            </button>
            <button className="create-account" data-cy="sign-up-header-btn" onClick={signUp}>
              {t("register.createAccount")}
            </button>
          </div>
        </>
      }
    </StyledTabletHeader>
  </AffixStyled>
});