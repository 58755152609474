import { memo, useCallback, useEffect, useState } from "react";

import {
  LANGUAGES_LS_KEY,
  Translations,
  TType,
  useTranslate,
} from "../../../assets/translations";

import ProfileSiderMenu, {
  ProfileSiderMenuButtonItem,
} from "./profile-sider-menu/profile-sider-menu";

import {
  getAdminNavMenu,
  getMainNavMenu,
  getOtherNavMenu,
  getRecalculateButton,
  getSupportMenuItem,
} from "./teacher-sidebar-items";
import {
  ChevronHorizontalIcon,
  NavDividerSider,
  NavSiderButtonItem,
  NavSiderImageBlock,
  NavSiderSystemBlock,
  NavInfoSider,
  NavSider,
  NavSiderInnerContainer,
} from "./profile-sider-styled";

import { getUsersPicture } from "../../../services/user-service";

import { AdminProfile, StudentProfile, TUserProfileData } from "../../../types";
import { useNavigate } from "react-router-dom";
import { LogoutIcon } from "../../../assets/images/svg-elements/logout";
import { logoutClickKey, saveToLs } from "../../../services/storage-service";
import { NavSiderTooltip } from "./profile-sider-menu/profile-sider-menu-styled";
import { useGetUser } from "../../../hooks/useGetUser";
import { useVisibleWithRect } from "../../../hooks/useVisibleWithRect";
import { createPortal } from "react-dom";
import { useIsResponsive } from "../../../hooks/useIsResponsive";
import { useDisableScroll } from "../../../hooks";
import { LanguageSwitch } from "../language-switch";
import { RecalculateIndexButton } from "../../pages/back-office-page/components/elements/recalculate-index-button/recalculate-index-button";
import { useRecalculateIndex } from "../../../hooks/useRecalculateIndex";
import {Hint} from "../hint";

interface ProfileSiderProps {
  user: TUserProfileData | StudentProfile | AdminProfile;
  t: TType;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  administrator: boolean;
  navigationDisabled?: boolean;
  dataCy?: string;
}

const ProfileSider = memo(
  ({ user, t, isOpen, setIsOpen, administrator, navigationDisabled, dataCy }: ProfileSiderProps) => {
    const [activeKey, setActiveKey] = useState<string>();

    const { isMobile, isTablet } = useIsResponsive();

    const { authority } = useGetUser();

    const { language, setLanguage } = useTranslate();

    const { cooldown, recalculateIndex } = useRecalculateIndex();

    const mainNavMenu = getMainNavMenu(
      t,
      authority,
      user.connectionRequestsCounter
    );
    const otherNavMenu = getOtherNavMenu(t, authority, isMobile);
    const supportMenuItem = getSupportMenuItem(t);
    const recalculateButton = getRecalculateButton(
      <p style={{ maxWidth: "95%" }}>
        Оновити індекс{" "}
        <span style={{ color: "red" }}>
          {!!cooldown && `через ${cooldown} сек.`}
        </span>
      </p>
    );

    useDisableScroll(isOpen, true);

    const navigate = useNavigate();

    const { avatarId, firstName, lastName } = user;

    const handleCollapse = () => {
      setIsOpen(!isOpen);
      setActiveKey("");
    };

    const logOut = async () => {
      saveToLs(logoutClickKey, true);
      navigate("/logout");
    };

    useEffect(() => {
      const handleOutsideClick = (e: MouseEvent) => {
        if (!isOpen && !(e.target as HTMLElement).closest(".sider")) {
          setActiveKey(undefined);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);
      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, [isOpen]);

    const onLanguageChange = useCallback((value: string) => {
      saveToLs(LANGUAGES_LS_KEY, value);
      setLanguage(value as Translations);
    }, []);

    const collapsedCondition = !isOpen ? "true" : undefined;
    const mobileOpenCondition = (isMobile || isTablet) && isOpen;

    const {
      hideElement: hideLogOutElement,
      isVisible: isLogOutVisible,
      rect: logOutRect,
      ref: logOutRef,
      showElement: showLogOutElement,
    } = useVisibleWithRect<HTMLButtonElement>();

    const container = document.querySelector(".sider_modal");

    return (
      <NavSider
        data-cy={dataCy}
        collapsed={collapsedCondition}
        className={`sider ${mobileOpenCondition && "mobile-open"}`}
      >
        <NavSiderInnerContainer>
          <div className="sider_modal"></div>
          <NavInfoSider collapsed={collapsedCondition}>
            <img src={getUsersPicture(avatarId)} alt="user image" />
            <p>
              {firstName} {lastName}
            </p>
          </NavInfoSider>
          <NavDividerSider collapsed={collapsedCondition}>
            <div className="line" />
            {!(isMobile || isTablet) && (
              <button onClick={handleCollapse}>
                <ChevronHorizontalIcon collapsed={collapsedCondition} />
              </button>
            )}
          </NavDividerSider>
          <ProfileSiderMenu
            t={t}
            title={t("sidebar.main-menu")}
            activeKey={activeKey}
            isCollapsed={collapsedCondition}
            setActiveKey={setActiveKey}
            items={administrator ? getAdminNavMenu() : mainNavMenu}
            setIsOpen={setIsOpen}
            navigationDisabled={navigationDisabled}
          />
          <NavDividerSider collapsed={collapsedCondition}>
            <div className="line" />
          </NavDividerSider>
          {!administrator && (
            <>
              <ProfileSiderMenu
                t={t}
                title={t("sidebar.other-menu")}
                activeKey={activeKey}
                isCollapsed={collapsedCondition}
                setActiveKey={setActiveKey}
                items={otherNavMenu}
                setIsOpen={setIsOpen}
                navigationDisabled={navigationDisabled}
              />
              <NavDividerSider collapsed={collapsedCondition}>
                <div className="line" />
              </NavDividerSider>
            </>
          )}
        </NavSiderInnerContainer>
        <NavSiderSystemBlock collapsed={collapsedCondition}>
          <ul>
            {(isMobile || isTablet) && (
              <LanguageSwitch
                language={language}
                t={t}
                onLanguageChange={onLanguageChange}
              />
            )}
            {!administrator && (
              <li
                className={
                  supportMenuItem.key === activeKey ? "active" : "inactive"
                }
              >
                <ProfileSiderMenuButtonItem
                  setActiveKey={setActiveKey}
                  setIsOpen={setIsOpen}
                  isCollapsed={collapsedCondition}
                  activeKey={activeKey}
                  el={supportMenuItem}
                />
              </li>
            )}
            {administrator && (
              <li>
                <ProfileSiderMenuButtonItem
                  setActiveKey={setActiveKey}
                  setIsOpen={setIsOpen}
                  isCollapsed={collapsedCondition}
                  activeKey={activeKey}
                  el={recalculateButton}
                  handleClick={recalculateIndex}
                />
              </li>
            )}
            <NavSiderButtonItem
              collapsed={collapsedCondition}
              onClick={logOut}
              ref={logOutRef}
              onMouseEnter={showLogOutElement}
              onMouseLeave={hideLogOutElement}
            >
              <div className="button-container">
                <div className="info-block">
                  <NavSiderImageBlock collapsed={collapsedCondition}>
                    <LogoutIcon />
                  </NavSiderImageBlock>
                  <p className="logout">{t("login.form.button.signout")}</p>
                  {isOpen &&
                    container &&
                    createPortal(
                      <NavSiderTooltip
                        className="tooltiptext"
                        rect={logOutRect}
                        isVisible={isLogOutVisible}
                        style={{
                          top: logOutRect
                            ? logOutRect?.top - logOutRect.height - 10
                            : 0,
                          left: logOutRect
                            ? logOutRect.left + logOutRect.width + 13
                            : 0,
                        }}
                      >
                        {t("login.form.button.signout")}
                      </NavSiderTooltip>,
                      container as Element
                    )}
                </div>
              </div>
            </NavSiderButtonItem>
          </ul>
        </NavSiderSystemBlock>
      </NavSider>
    );
  }
);

export default ProfileSider;
