import { useState, useMemo } from "react";
import { TType } from "../assets/translations";

export const useFormattedMoreMessage = (
  message: string | null,
  maxSymbols: number,
  t: TType,
) => {
  const [isShowMore, setIsShowMore] = useState(false);

  const handleIsShowMore = () => {
    setIsShowMore((prev) => !prev);
  };

  const isMessageLongerThanMaxSymbols = useMemo(() => {
    return message ? message.length > maxSymbols : false;
  }, [message, maxSymbols]);

  const formattedMessage = useMemo(() => {
    if (!message) return "";
    if (isShowMore || !isMessageLongerThanMaxSymbols) {
      return message;
    }
    return message.slice(0, maxSymbols) + "...";
  }, [message, isShowMore, maxSymbols, isMessageLongerThanMaxSymbols]);

  const formattedMessageWithButton = useMemo(() => {
    if (!isMessageLongerThanMaxSymbols) return formattedMessage;

    return (
      <>
        {formattedMessage}
        <button
          onClick={handleIsShowMore}
          style={{
            border: "none",
            background: "transparent",
            color: "blue",
            cursor: "pointer",
          }}
        >
          {isShowMore ? t("lesson-requests.less") : t("lesson-requests.more")}
        </button>
      </>
    );
  }, [formattedMessage, isMessageLongerThanMaxSymbols, isShowMore]);

  const moreButton = useMemo(() => {
    return (
      <button
      onClick={handleIsShowMore}
      style={{
        border: "none",
        background: "transparent",
        color: "blue",
        cursor: "pointer",
      }}
    >
      {isShowMore ? t("lesson-requests.less") : t("lesson-requests.more")}
    </button>
    )
  }, [isShowMore])

  return {
    isMessageLongerThanMaxSymbols,
    formattedMessageWithButton,
    isShowMore,
    handleIsShowMore,
    moreButton,
    formattedMessage
  };
};
