import { FC, useEffect, useState } from "react";
import { Radio, RadioChangeEvent } from "antd";
import { useMediaQuery } from "react-responsive";

import { device } from "../../../../../constants";
import {
  Filter,
  RadioData,
  TagsRemoveMethodsBindings,
} from "../../search-input-filter.types";
import { CityData, LessonType, setState } from "../../../../../../types";
import { TType } from "../../../../../../assets/translations";
import {
  AvailabilityFilterStyled,
  RadioGroup,
} from "./availability-filter.styled";
import { capitalizeFirstLetter } from "../../../../../../services/teacher-service";
import { CityFilter } from "../city-filter-redesigned/city-filter";
import { useSearchBarTabsUpdateState } from "../../../../../../context/SearchBarTabsContext";

interface Props {
  t: TType;
  filterData: Filter;
  setFilterData: setState<Filter>;
  setTags: setState<TagsRemoveMethodsBindings[]>;
  tagName?: string;
  performSearchWithButton?: () => void;
}

const getValues = (t: TType): RadioData[] => [
  {
    value: LessonType.ONLINE,
    name: t(`lesson-types.${LessonType.ONLINE.toLowerCase()}`),
  },
  {
    value: LessonType.OFFLINE,
    name: t(`lesson-types.${LessonType.OFFLINE.toLowerCase()}`),
  },
];

export const AvailabilityFilter: FC<Props> = ({
  t,
  filterData,
  setFilterData,
  setTags,
  tagName = "availabilityStatusTag",
  performSearchWithButton,
}) => {
  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const [status, setStatus] = useState<LessonType | undefined>(
    (capitalizeFirstLetter(filterData.lessonTypes?.[0] || "") as LessonType) ||
      undefined
  );
  const [selectedCity, setSelectedCity] = useState<CityData | null>(null);

  const searchBarTabsUpdateState = useSearchBarTabsUpdateState();

  const onChange = (e: RadioChangeEvent) => {
    setStatus(e.target.value);

    isMobile && applyAvailabilityStatus(e.target.value);
  };

  useEffect(() => {
    if (!selectedCity) {
      return;
    }

    applyAvailabilityStatus();
  }, [selectedCity]);



  const applyAvailabilityStatus = (
    statusValue = status,
    applyButton?: boolean
  ) => {
    if (statusValue) {
      searchBarTabsUpdateState &&
        searchBarTabsUpdateState((prev) => ({
          ...prev,
          status: { status: false, value: !!statusValue },
          prices: { ...prev.prices, status: !prev.prices.value },
        }));
    }

    if (statusValue === undefined && applyButton) {
      searchBarTabsUpdateState &&
        searchBarTabsUpdateState((prev) => ({
          ...prev,
          status: { status: false, value: !!statusValue },
          prices: { ...prev.prices, status: !prev.prices.value },
        }));
    } else if (statusValue === LessonType.ONLINE) {
      setFilterData((prev) => ({
        ...prev,
        lessonTypes: [LessonType.ONLINE.toUpperCase()],
        cities: undefined,
      }));
      setTags((prev) => {
        const tag = prev.find((tag) => tag.id === tagName);

        if (tag) {
          return [
            ...prev.map((tag) =>
              tag.id === tagName
                ? {
                    ...tag,
                    title: t(`lesson-types.${LessonType.ONLINE.toLowerCase()}`),
                    removeTagMethod,
                  }
                : { ...tag }
            ),
          ];
        } else {
          return [
            ...prev,
            {
              id: tagName,
              title: t(`lesson-types.${LessonType.ONLINE.toLowerCase()}`),
              removeTagMethod,
            },
          ];
        }
      });
    } else {
      if (!selectedCity) return;
      setFilterData((prev) => ({
        ...prev,
        cities: [selectedCity.code],
        lessonTypes: [LessonType.OFFLINE.toUpperCase()],
      }));
      
      setTags((prev) => {
        const tag = prev.find((tag) => tag.id === tagName);

        if (tag) {
          return [
            ...prev.map((tag) =>
              tag.id === tagName
                ? {
                    ...tag,
                    title: selectedCity.name,
                    removeTagMethod,
                  }
                : { ...tag }
            ),
          ];
        } else {
          return [
            ...prev,
            {
              id: tagName,
              title: selectedCity.name,
              removeTagMethod,
            },
          ];
        }
      });
    }
  };

  const resetAvailabilityStatus = () => {
    setTags((prev) => prev.filter((tag) => tag.id !== tagName));
    setFilterData((prev) => ({
      ...prev,
      lessonTypes: undefined,
      cities: undefined,
    }));
    
    searchBarTabsUpdateState &&
      searchBarTabsUpdateState((prev) => ({
        ...prev,
        status: { ...prev.status, value: false },
      }));
    setStatus(undefined);
    setSelectedCity(null);
  };

  const removeTagMethod = () => {
    resetAvailabilityStatus();
  };

  return (
    <AvailabilityFilterStyled className="availability-filter-container">
      <div className="radio-group-container">
        {!isMobile && <h2>{t("searchpage.filters.availability.title")}</h2>}

        <RadioGroup
          className="filter-radio-group"
          value={status}
          onChange={onChange}
        >
          {getValues(t).map((entry) => (
            <Radio
              key={entry.name}
              className="filter-radio"
              value={entry.value}
            >
              {entry.name}
            </Radio>
          ))}
        </RadioGroup>
      </div>

      <CityFilter
        t={t}
        setTags={setTags}
        filterData={filterData}
        selectedCity={selectedCity}
        setFilterData={setFilterData}
        setSelectedCity={setSelectedCity}
        showCitiesList={status === LessonType.OFFLINE}
      />

      {!isMobile && (
        <div className="controls">
          <button
            data-cy="availability-filter-reset-button"
            className="reset"
            onClick={resetAvailabilityStatus}
          >
            {t("searchpage.reset")}
          </button>
          <button
            data-cy="availability-filter-apply-button"
            className="apply"
            onClick={() => applyAvailabilityStatus(status, true)}
          >
            {t("searchpage.apply")}
          </button>
        </div>
      )}
    </AvailabilityFilterStyled>
  );
};
