import { FC, useMemo } from "react";

import { TType } from "../../../../assets/translations";

import { CustomLoader } from "../../../elements/custom-loader";
import { NotFoundComponent } from "../../../elements/not-found-component";

import LessonRequestHeader from "../lesson-request-header/lesson-request-header";

import { TeacherLessonMenuTabs, UnionTabsType } from "../lesson-request";

import { FAQMenu } from "../../../../types/FAQ";
import {
  AdminProfile,
  GetRequestsLessonStatuses,
  LessonRequestType,
  RequestLesson,
  TUserProfileData,
} from "../../../../types";
import TeacherPublicCard from "../lesson-request-card/teacher-public-card/teacher-public-card";
import TeacherDirectCard from "../lesson-request-card/teacher-direct-card/teacher-direct-card";
import { NavLink, useNavigate } from "react-router-dom";
import { handleChangeRequestStatus } from "../services/handleChangeRequestStatus";
import { useQueryClient } from "@tanstack/react-query";
import CardActionModal from "../../../modals/card-action-modal/card-action-modal";
import { useActionModal } from "../../../../hooks/useActionModal";

export interface UserLessonRequestProps {
  t: TType;
  requests: RequestLesson[];
  isSwitchActive: boolean;
  setIsSwitchActive: (state: boolean) => void;
  isLoading: boolean;
  selectedKey: UnionTabsType;
  handleChangeSelectedKey: (key: UnionTabsType) => void;
  user: AdminProfile | TUserProfileData | null;
  categoryIds: string[];
  openDirectRequestsCount: number;
  goToPage?: (value: number | string) => void
}

export interface DataForAccept {
  categoryId: string;
  connectionRequestId: string;
  studentId: string;
  teacherId: string;
  successMsg?: string;
}

enum ReqTabs {
  REQUESTS_FROM_STUDENTS = "requests-from-students",
  OPPORTUNITY_SPOT = "opportunity-spot",
}

const SEARCH_MAPPING: Record<keyof TeacherLessonMenuTabs, boolean> = {
  opportunity_spot: false,
  requests_from_students: false,
};

const FILTER_INPUT_MAPPING: Record<
  keyof TeacherLessonMenuTabs,
  [boolean, boolean]
> = {
  requests_from_students: [false, false],
  opportunity_spot: [true, false],
};

const TeacherLessonRequest: FC<UserLessonRequestProps> = ({
  t,
  requests,
  isSwitchActive,
  setIsSwitchActive,
  isLoading,
  selectedKey,
  handleChangeSelectedKey,
  user,
  categoryIds,
  goToPage
}) => {
  const selectedTeacherKey = selectedKey as keyof TeacherLessonMenuTabs;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const acceptModal = useActionModal<DataForAccept>();
  const rejectModal = useActionModal<DataForAccept>();

  const handleConfirmAccept = async (shouldNavigate: boolean) => {
    handleChangeRequestStatus({
      queryClient,
      newStatus: GetRequestsLessonStatuses.ACCEPTED,
      categoryId: acceptModal.selectedItem?.categoryId ?? "",
      connectionRequestId: acceptModal.selectedItem?.connectionRequestId ?? "",
      navigate: shouldNavigate ? navigate : null,
      studentId: acceptModal.selectedItem?.studentId ?? "",
      teacherId: acceptModal.selectedItem?.teacherId ?? "",
    });

    acceptModal.closeModal();
  };

  const handleConfirmReject = async (shouldNavigate: boolean) => {
    handleChangeRequestStatus({
      queryClient,
      newStatus: GetRequestsLessonStatuses.CANCELLED,
      categoryId: rejectModal.selectedItem?.categoryId ?? "",
      connectionRequestId: rejectModal.selectedItem?.connectionRequestId ?? "",
      navigate: shouldNavigate ? navigate : null,
      studentId: rejectModal.selectedItem?.studentId ?? "",
      teacherId: rejectModal.selectedItem?.teacherId ?? "",
    });

    rejectModal.closeModal();
  };

  const MENU_TITLES: FAQMenu<TeacherLessonMenuTabs>[] = [
    {
      id: 0,
      label: t(`lesson-requests.tabs.${ReqTabs.REQUESTS_FROM_STUDENTS}`),
      key: "requests_from_students",
    },
    {
      id: 1,
      label: t(`lesson-requests.tabs.${ReqTabs.OPPORTUNITY_SPOT}`),
      key: "opportunity_spot",
    },
  ];

  const REQUEST_LIMIT_MAPPING: Record<keyof TeacherLessonMenuTabs, boolean> = {
    requests_from_students: false,
    opportunity_spot: false,
  };

  const renderCards = useMemo(() => {
    if (isLoading) {
      return <CustomLoader />;
    }

    if (!requests || requests.length === 0) {
      return (
        <NotFoundComponent
          text={
            <p>
              {t("lesson-requests.not-found-first-half")}{" "}
              <NavLink to="/lesson-requests/opportunity-spot?filter=ALL">
                {t("lesson-requests.not-found-second-half")}
              </NavLink>
            </p>
          }
          style={{ height: "60vh" }}
        />
      );
    }

    return (
      <div className="cards">
        {requests.map((request) =>
          request.type === LessonRequestType.STUDENT_DIRECT ? (
            <TeacherDirectCard
              card={request}
              t={t}
              key={request.id}
              openAcceptModal={acceptModal.openModal}
              openRejectModal={rejectModal.openModal}
            />
          ) : (
            <TeacherPublicCard
              card={request}
              t={t}
              key={request.id}
              clarificationNeeded={user?.clarificationNeeded}
              categoryIds={categoryIds}
            />
          )
        )}
      </div>
    );
  }, [isLoading, requests, t]);

  return (
    <>
      <LessonRequestHeader
        isShowActiveOnly
        handleChangeSelectedKey={handleChangeSelectedKey}
        menuTitles={MENU_TITLES}
        selectedKey={selectedTeacherKey}
        t={t}
        requestsLength={
          requests.filter(
            (request) => request.status === GetRequestsLessonStatuses.CREATED
          ).length || 0
        }
        handleRenderFilterInputs={(key) => FILTER_INPUT_MAPPING[key]}
        showRequestLimit={(key) => REQUEST_LIMIT_MAPPING[key]}
        setIsSwitchActive={setIsSwitchActive}
        isSwitchActive={isSwitchActive}
        switchLabel={t("lesson-requests.switch")}
        isShowSearchFn={(key) => SEARCH_MAPPING[key]}
        goToPage={goToPage}
      />
      {renderCards}

      {rejectModal.isOpen && (
        <CardActionModal
          open={rejectModal.isOpen}
          handleRequest={() => handleConfirmReject(false)}
          handleCancel={rejectModal.closeModal}
          header={t("lesson-requests.modals.reject-offer-t")}
          paragraph={t("lesson-requests.modals.reject-offer-desc")}
          t={t}
        />
      )}

      {acceptModal.isOpen && (
        <CardActionModal
          open={acceptModal.isOpen}
          handleRequest={() => handleConfirmAccept(false)}
          handleRequestCreateLesson={() => handleConfirmAccept(true)}
          handleCancel={acceptModal.closeModal}
          header={t("lesson-requests.modals.accept-offer-t")}
          paragraph={t("lesson-requests.modals.accept-offer-desc-t")}
          isCreateLesson
          t={t}
        />
      )}
    </>
  );
};

export default TeacherLessonRequest;
