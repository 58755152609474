export const FILL_LOGIN_INFO = "FillLoginInfo";

export const SHOW_UPLOAD_RESULT_MODAL = "ShowUploadResultModal";
export const HIDE_UPLOAD_RESULT_MODAL = "HideUploadResultModal";

export const SHOW_REVIEW_RESULT_MODAL = "ShowReviewResultModal";
export const HIDE_REVIEW_RESULT_MODAL = "HideReviewResultModal";

export const SHOW_REVIEW_HOMEWORK_MODAL = "ShowReviewHomeworkModal";
export const HIDE_REVIEW_HOMEWORK_MODAL = "HideReviewHomeworkModal";

export const CLEAR_LOGIN_INFO = "ClearLoginInfo";