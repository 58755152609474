import { FC, useEffect, useState } from "react";
import { Collapse, Menu, Radio, RadioChangeEvent } from "antd";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import { useMediaQuery } from "react-responsive";

import { device } from "../../../../../constants";
import {
  CategoryMobile,
  Filter,
  TagsRemoveMethodsBindings,
} from "../../search-input-filter.types";
import { Category, setState } from "../../../../../../types";
import { MenuStyled, Panel, SkillsFilterStyled } from "./skills-filter.styled";
import { useSearchBarTabsUpdateState } from "../../../../../../context/SearchBarTabsContext";

interface Props {
  setFilterData: setState<Filter>;
  setTags: setState<TagsRemoveMethodsBindings[]>;
  tagName?: string;
  storedCategories: Category[];
}

export const SkillsFilterComponent: FC<Props> = ({
  setFilterData,
  setTags,
  tagName = "categoryTag",
  storedCategories,
}) => {
  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const [categoriesOptions, setCategoriesOptions] = useState<ItemType[]>([]);

  const [categoriesMobileOptions, setCategoriesMobileOptions] = useState<
    CategoryMobile[]
  >([]);

  const [selectedCategory, setSelectedCategory] = useState<
    Category | undefined
  >(undefined);

  const searchBarTabsUpdateState = useSearchBarTabsUpdateState();

  useEffect(() => {
    if (storedCategories.length) {
      const categories = storedCategories.map((category: Category) => {
        const childItems: ItemType[] = [
          {
            label: category.name,
            key: category.code!,
            type: "group",
          },
        ];

        if (category.childrenCategories?.length) {
          category.childrenCategories.forEach((child) => {
            childItems.push({
              label: child.name,
              key: child.code!,
            });
          });
        }

        const categoryChildren = storedCategories.flatMap(
          (cat) => cat.childrenCategories!
        );

        const onChange = (info: any) => {
          searchBarTabsUpdateState &&
            searchBarTabsUpdateState((prev) => ({
              ...prev,
              skills: { ...prev.skills, status: false },
              status: { ...prev.status, status: !prev.status.value },
            }));
          const selectedCategory = categoryChildren.find(
            (cat) => String(cat.code) === info.key
          );
          addCategoryTag(selectedCategory!);
        };

        return {
          label: <Menu onClick={onChange} items={childItems} />,
          key: category.name,
          style: {
            height: "initial",
          },
        };
      });

      const mobileCategories = storedCategories.map((category: Category) => ({
        key: String(category.code),
        header: category.name,
        children: category.childrenCategories!,
      }));

      setCategoriesMobileOptions(mobileCategories);

      setCategoriesOptions(categories);
    }
  }, [storedCategories]);

  const resetCategories = () => {
    setTags((prev) => prev.filter((tag) => tag.id !== tagName));
    setFilterData((prev) => ({ ...prev, categories: undefined }));
    searchBarTabsUpdateState &&
      searchBarTabsUpdateState((prev) => ({
        ...prev,
        skills: { ...prev.skills, value: false },
      }));
    setSelectedCategory(undefined);
  };

  const removeTagMethod = () => {
    resetCategories();
  };

  const addCategoryTag = (category: Category): void => {
    setTags((prev) => {
      const tag = prev.find((tag) => tag.id === tagName);

      if (tag) {
        return [
          ...prev.map((tag) =>
            tag.id === tagName
              ? {
                  ...tag,
                  title: category.name,
                  removeTagMethod,
                }
              : { ...tag }
          ),
        ];
      } else {
        return [
          ...prev,
          {
            id: tagName,
            title: category.name,
            removeTagMethod,
          },
        ];
      }
    });

    setFilterData((prev) => ({ ...prev, categories: [category.code] }));
  };

  const onCategoryChange = (e: RadioChangeEvent) => {
    const category = e.target.value;

    setSelectedCategory(category);
    addCategoryTag(category);
  };

  return (
    <SkillsFilterStyled>
      {isMobile ? (
        <Radio.Group
          style={{ width: "100%" }}
          value={selectedCategory}
          onChange={onCategoryChange}
        >
          <Collapse ghost accordion defaultActiveKey={["1"]}>
            {categoriesMobileOptions.map(({ key, header, children }) => (
              <Panel key={key} header={header}>
                {children.map((childCat) => (
                  <Radio.Button key={childCat.code} value={childCat}>
                    {childCat.name}
                  </Radio.Button>
                ))}
              </Panel>
            ))}
          </Collapse>
        </Radio.Group>
      ) : (
        <MenuStyled items={categoriesOptions} />
      )}
    </SkillsFilterStyled>
  );
};
