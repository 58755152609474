import {CSSProperties, FC} from "react";
import {Tag} from "antd";

import {TagsRemoveMethodsBindings} from "../search-input-filter.types";
import {TType} from "../../../../../assets/translations";
import {SearchTagsStyled} from "./search-tags.styled";


interface Props {
    t: TType;
    tags: TagsRemoveMethodsBindings[];
    clearAll: () => void;
    style?: CSSProperties;
}

export const SearchTags: FC<Props> = ({
                                          t,
                                          tags,
                                          clearAll,
                                          style = {},
                                      }) => {

    return <SearchTagsStyled
        data-cy="search-tags-holder"
        style={style}
        enter={{
            scale: 0.8,
            opacity: 0,
            type: "from",
            duration: 100,
        }}
        onEnd={e => {
            if (e.type === "appear" || e.type === "enter") {
                (e.target as any).style = "display: inline-block";
            }
        }}
        leave={{opacity: 0, width: 0, scale: 0, duration: 200}}
        appear={false}
    >
        {tags.map((tag: TagsRemoveMethodsBindings) => {
            return <span key={tag.id} style={{display: "inline-block"}}>
           <Tag className="search-tag" closable onClose={() => tag.removeTagMethod()}>
                {tag.title}
            </Tag>
        </span>
        })}
        {!!tags.length && <span data-cy="clear-all-button" className="clear-all"
                                onClick={clearAll}>{t("searchpage.tags-clear-all")}</span>}
    </SearchTagsStyled>;
};
