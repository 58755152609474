import {FC, ReactElement, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

import {ErrorPage, ErrorPageTypes} from "../components/pages";
import {FILL_LOGIN_INFO} from "../components/redux";
import {TType} from "../assets/translations";


interface Props {
    isAuthenticated: boolean
    children: ReactElement | null;
    t: TType;
}

export const SecureRoute: FC<Props> = ({isAuthenticated, children, t}) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const {pathname, state} = useLocation();

    const login = () => {
            dispatch({
                type: FILL_LOGIN_INFO,
                loginText: t("login-text.default"),
                callbackAfterLogin: () => navigate(pathname, {state})
            });

            return navigate("/login");
    }

    useEffect(() => {
        !isAuthenticated && login();
    }, [isAuthenticated]);


    if (!isAuthenticated) {
        return <ErrorPage t={t} type={ErrorPageTypes.ACCESS_DENIED}/>;
    }

    return children;
}
