import ReactGA from "react-ga4";
import pluralize from "pluralize";
import {UaEventOptions} from "react-ga4/types/ga4";

import {normalizeDateToShow} from "./date-service";
import emptyAvatar from "../assets/images/webp/empty-avatar.webp";
import {Translations, TType} from "../assets/translations";
import CategoryFallbackImage from "../assets/images/png/category-fallback.png";
import { CityData } from "../types";


const getUsersPicture = (avatarId: string | null | undefined): string => avatarId ?
		`${process.env.REACT_APP_USER_PHOTO_URL}${avatarId}_compressed` : emptyAvatar;

const getUsersVideo = (videoId: string | null | undefined): string => videoId ?
		`${process.env.REACT_APP_USER_VIDEO_URL}${videoId}` : "";
	
const getCategoryIcon = (cateryCode: string | null | undefined): string => cateryCode ? 
		`${process.env.REACT_APP_CATEGORY_ICON_URL}${cateryCode}` : CategoryFallbackImage

const validateEmail = (email: string, t: TType): string => {
		if (email.length) {
				return isEmailCorrect(email) ? "" : t("validation.email-invalid");
		}

		return t("validation.email-required");
};

const validatePhone = (phone: string, t: TType): string => {
		const minPhoneNumberLength = 9;
		const maxPhoneNumberLength = 9;

		const isNumberTooShort = phone.length < minPhoneNumberLength;
		const isNumberTooLong = phone.length > maxPhoneNumberLength;

		if (!phone.length) {
				return t("validation.phone-required");
		} else if (isNumberTooLong || isNumberTooShort) {
				return t("validation.phone-requirements");
		} else if (!/^\d+$/.test(phone)) {
				return t("validation.phone-invalid");
		} else {
				return "";
		}

};

const isEmailCorrect = (email: string): boolean => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/.test(email);

const refresh = (): void => {
		console.log("refresh...");
};

const pressEnterHandler = (event: KeyboardEvent, callback: () => void): void => {
		if (event.key === "Enter") {
				callback();
		}
};

function convertHoursToDisplay(hours: number, t: TType) {
		if (hours < 1) {
				return `${+hours.toFixed(1) * 60} ${t("minutes")}`;
		}

		const bigHours = Math.floor(hours);

		if (bigHours < 72) {
				return `${bigHours} ${t("hours")}`;
		}

		const days = Math.floor(bigHours / 24);
		const remainingHours = bigHours % 24;

		if (days < 7) {
				if (remainingHours === 0) {
						return `${days} ${t("days")}`;
				} else {
						return t("days-and-hours", {days, hours: remainingHours});
				}
		}

		const weeks = Math.floor(days / 7);
		const remainingDays = days % 7;

		if (remainingDays === 0) {
				return `${weeks} ${t("weeks")}`;
		} else if (remainingHours === 0) {
				return t("weeks-and-days", {weeks, days: remainingDays});
		} else {
				return t("weeks-days-hours", {weeks, days: remainingDays, hours: remainingHours});
		}
}

const isUserOnline = (lastActiveTime: string, lastOnlineMinutes = 5): boolean => (new Date().getTime() - new Date(lastActiveTime).getTime()) < lastOnlineMinutes * 60000;

const getLastActiveTime = (lastActiveTime: string | null, t: TType, locale: Translations) => {
		if (lastActiveTime === null) {
				return t("long-time-offline");
		}

		if (isUserOnline(lastActiveTime)) {
				return t("messenger.active");
		} else {
				const msInUtc = new Date(lastActiveTime.slice(0, lastActiveTime.length - 1)).getTime();
				return `${t("was-online")} ${formatMilliseconds(msInUtc, t, locale)}`;
		}
};

function formatMilliseconds(currentTime: number, t: TType, locale: Translations) {
		const now = new Date();
		let milliseconds = new Date(now.getTime() + now.getTimezoneOffset() * 60000).getTime() - currentTime;

		const msPerMinute = 60 * 1000;
		const msPerHour = msPerMinute * 60;
		const msPerDay = msPerHour * 24;

		const days = Math.floor(milliseconds / msPerDay);
		milliseconds %= msPerDay;

		const hours = Math.floor(milliseconds / msPerHour);

		if (!hours && !days) {
				return `${t("few-minutes")} ${t("ago")}`;
		}

		if (days >= 5) {
				return normalizeDateToShow(new Date(currentTime), locale);
		} else {

				const daysPluralized = locale === Translations.en ? pluralize("day", days, false) :
						pluralizeUk(["день", "дні", "днів"], days, false);

				const hoursPluralized = locale === Translations.en ? pluralize("hr", hours, false) :
						pluralizeUk(["годину", "години", "годин"], hours, false);

				let formattedTime = "";
				if (days > 0) formattedTime += `${days} ${daysPluralized}, `;
				if (hours > 0) formattedTime += `${hours} ${hoursPluralized}, `;
				return `${formattedTime.slice(0, -2)} ${t("ago")}`;
		}
}

// For next func you need to pass 3 options to return them depending on condition. Ex:
//  console.log(pluralizeUk(["день", "дні", "днів"], 42))

const pluralizeUk = (forms: string[], n: number, inclusive = true) => {
		if (n % 10 === 1 && n % 100 !== 11) {
				// For numbers ending in 1, excluding numbers ending in 11.
				return `${inclusive ? n : ""} ${forms[0]}`;
		} else if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) {
				// For numbers ending in 2, 3, or 4, excluding numbers ending in 12, 13, or 14.
				return `${inclusive ? n : ""} ${forms[1]}`;
		} else {
				// For all other numbers or numbers ending in 0 or numbers ending in 11, 12, 13, or 14.
				return `${inclusive ? n : ""} ${forms[2]}`;
		}
};

const removeUndefinedValues = (obj: any) => {
		const filteredObj: any = {};

		for (const key in obj) {
				if (obj[key] !== undefined) {
						filteredObj[key] = obj[key];
				}
		}

		return filteredObj;
};

const sendAnalyticEvent = (optionsOrName: UaEventOptions | string, params?: any) => {
		ReactGA.event(optionsOrName, params);
};

const getCategoryImageByName = (categoryName: string): string => {
	const ids: { [key: string]: string } = {
		Ukrainian: "7e0a3fe7-62a3-4393-a26c-460e27f9ab9e",
		English: "cb7a985b-2aab-4507-94a6-5ed08e24b312",
		German: "7e0a3fe7-62a3-4393-a26c-460e27f9ab9e",
		Spanish: "7e0a3fe7-62a3-4393-a26c-460e27f9ab9e",
		French: "7e0a3fe7-62a3-4393-a26c-460e27f9ab9e",
		Italian: "7e0a3fe7-62a3-4393-a26c-460e27f9ab9e",
		Poland: "7e0a3fe7-62a3-4393-a26c-460e27f9ab9e",
		Mathematics: "ebfb6853-a607-44e3-8060-cfb8a0cf83aa",
		Physics: "4b609a38-7db9-4240-b788-4a02310371f8",
		Chemistry: "a9855bf7-2481-4bbd-b5d2-57c8084c272a",
		Biology: "ae1a1dc8-9e4b-46a5-9438-d65a182eb20f",
		Reading: "792b9bdd-a586-432b-98c1-2a2b4205bbae",
		Tennis: "2a4d5b2a-c388-44e1-9f95-487ae9bf922e",
		Football: "794e5249-e7a3-4b8d-aa67-48ca06d1edc9",
		Basketball: "794e5249-e7a3-4b8d-aa67-48ca06d1edc9",
		Chess: "230f9e13-ac63-47bb-b8c0-09228c2baa9e",
		Instruments: "c78a5258-52ff-41a6-b93d-82cf15b4e9e8",
		Design: "478c9fbe-fb44-4f2d-831d-ef16b9ffee23",
		Painting: "a84e81b7-1c5b-4ec1-a4cc-d8fe5f8d044f",
		Vocal: "4ec7febf-d127-4d2d-808e-e6e278fa5096",
		'Martial Arts': "170efdcf-2668-4c85-a384-02f0523eff81",
		'Young Learners':"eb2670b1-d965-4ec7-a707-8e7bb99671e5",
		History:"b06148cf-ec3d-40f7-8f7b-9017c49ff6e7",
		Geography:"3bb3c541-2b3d-46fc-a373-bfb19290129d",
		Programming:"4fe4fc7c-2d8c-4edb-bf16-7b7d87dde49c"
	};

	return ids[categoryName];
};

const getCityByCode = (code: number | string, cities: CityData[]): CityData => {
	const city = cities.find((city: CityData) => city.code === +code);
    return city ?? { code: 0, name: ""}
}

export {
		getUsersPicture,
		validateEmail,
		validatePhone,
		refresh,
		pressEnterHandler,
		convertHoursToDisplay,
		pluralizeUk,
		removeUndefinedValues,
		formatMilliseconds,
		sendAnalyticEvent,
		getCategoryImageByName,
		isUserOnline,
		getLastActiveTime,
		getCategoryIcon,
		getUsersVideo,
		getCityByCode
};