import { FC, useState } from "react";
import { StudentDropdownWrapper } from "./student-dropdown-styled";
import StudentDropdownCard from "./student-dropdown-card/student-dropdown-card";
import { ApplicationStatuses, RequestLesson } from "../../../../../types";
import { TType } from "../../../../../assets/translations";
import { postAcceptApplicant } from "../../../../../api/lesson-api/postAcceptApplicant";
import { TeachingMeSuccessNotification } from "../../../../elements/notification";
import { isAxiosError } from "axios";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import CardActionModal from "../../../../modals/card-action-modal/card-action-modal";
import { useActionModal } from "../../../../../hooks/useActionModal";

interface StudentDropdownProps {
  t: TType;
  card: RequestLesson;
}

const handleActionApplicant = async (
  connectionRequestId: string,
  applicationId: string,
  status: ApplicationStatuses,
  queryClient: QueryClient,
  successNotification?: string,
  errorNotification?: string,
) => {
  try {
    const res = await postAcceptApplicant(
      connectionRequestId,
      applicationId,
      { status: status }
    );

    if (res.status === 200) {
      queryClient.refetchQueries(["studentActiveRequests"]);
      queryClient.refetchQueries(["UserProfile"]);
      
      successNotification &&
        TeachingMeSuccessNotification(successNotification);
    }
  } catch (error) {
    if (isAxiosError(error)) {
      errorNotification && TeachingMeSuccessNotification(errorNotification);
    }
  }
};

const StudentDropdown: FC<StudentDropdownProps> = ({ t, card }) => {
  const { applicants, id: connectionRequestId, studentId } = card;

  const acceptModal = useActionModal<string>();
  const rejectModal = useActionModal<string>();

  const queryClient = useQueryClient();

  

  const handleConfirmAccept = async () => {
    handleActionApplicant(
      connectionRequestId,
      acceptModal.selectedItem ?? "",
      ApplicationStatuses.ACCEPTED,
      queryClient,
      t("lesson-requests.norifications.approve")
    );

    acceptModal.closeModal()
  };

  const handleConfirmReject = async () => {
    handleActionApplicant(
      connectionRequestId,
      rejectModal.selectedItem ?? "",
      ApplicationStatuses.REJECTED,
      queryClient,
      t("lesson-requests.norifications.decline")
    );
    
    rejectModal.closeModal()
  };

  return (
    <StudentDropdownWrapper>
      {applicants.length > 0 &&
        applicants
          .filter(applicant => applicant.status !== ApplicationStatuses.REJECTED)
          .map(applicant => (
            <StudentDropdownCard
              key={applicant.id}
              teacher={applicant}
              connectionRequestId={connectionRequestId}
              studentId={studentId}
              t={t}
              openAcceptModal={acceptModal.openModal}
              openRejectModal={rejectModal.openModal}
              card={card}
            />
          ))
      }

      {rejectModal.isOpen && (
        <CardActionModal
          open={rejectModal.isOpen}
          handleRequest={handleConfirmReject}
          handleCancel={rejectModal.closeModal}
          header={t("lesson-requests.modals.reject-offer")}
          paragraph={t("lesson-requests.modals.reject-offer-desc")}
          t={t}
          />
      )}

      {acceptModal.isOpen && (
        <CardActionModal
          open={acceptModal.isOpen}
          handleRequest={handleConfirmAccept}
          handleRequestCreateLesson={handleConfirmAccept}
          handleCancel={acceptModal.closeModal}
          header={t("lesson-requests.modals.accept-offer")}
          paragraph={t("lesson-requests.modals.accept-offer-desc")}
          t={t}
        />
      )}
    </StudentDropdownWrapper>
  );
};

export default StudentDropdown;
