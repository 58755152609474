import { FC } from "react";
import { RequestLessonApplicants } from "../../../../../types";
import {
  StudentDropdownCardInfoWithPriceWrapper,
  StudentDropdownCardMessage,
  StudentDropdownCardPrice,
  StudentDropdownCardUserInfo,
} from "../student-dropdown/student-dropdown-card/student-dropdown-card-styled";
import { getUsersPicture } from "../../../../../services/user-service";
import { MoneyCard } from "../../../../../assets/images/svg-elements/money-card";
import { TType } from "../../../../../assets/translations";
import LessonRequestStatus from "../../lesson-request-status/lesson-request-status";
import { useIsResponsive } from "../../../../../hooks/useIsResponsive";
import { useFormattedMoreMessage } from "../../../../../hooks/useFormattedMoreMessage";
import { TeacherDropdownCardMessage, TeacherDropdownCardPrice, TeacherDropdownCardStatus, TeacherDropdownCardUserInfo, TeacherDropdownCardWrapper } from "./teacher-dropdown-styled";

interface TeacherDropdownCardProps {
  teacher: RequestLessonApplicants;
  t: TType;
}

const TeacherDropdownCard: FC<TeacherDropdownCardProps> = ({ teacher, t }) => {
  const { applicantAvatarId, applicantName, price, status, message } = teacher;
  const { isMobile } = useIsResponsive();

  const {
    formattedMessageWithButton,
  } = useFormattedMoreMessage(message, 100, t);

  return (
    <TeacherDropdownCardWrapper>
      {!isMobile && (
        <>
          <TeacherDropdownCardUserInfo>
            <img src={getUsersPicture(applicantAvatarId)} alt="user avatar" />
            <div className="personal_info">
              <div>
                <p>{applicantName}</p>
              </div>
            </div>
          </TeacherDropdownCardUserInfo>

          <TeacherDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </TeacherDropdownCardPrice>
        </>
      )}

      {isMobile && (
        <StudentDropdownCardInfoWithPriceWrapper>
          <StudentDropdownCardUserInfo>
            <img src={getUsersPicture(applicantAvatarId)} alt="user avatar" />
            <div className="personal_info">
              <div>
                <p>{applicantName}</p>
              </div>
            </div>
          </StudentDropdownCardUserInfo>

          <StudentDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </StudentDropdownCardPrice>
        </StudentDropdownCardInfoWithPriceWrapper>
      )}

      <TeacherDropdownCardStatus>
        <LessonRequestStatus status={status} t={t} />
      </TeacherDropdownCardStatus>

      <TeacherDropdownCardMessage>
        {message ? (
          <div className="container">
            <p>{formattedMessageWithButton}</p>
          </div>
        ) : (
          <p>{t("my-students.not-specified")}</p>
        )}
      </TeacherDropdownCardMessage>
    </TeacherDropdownCardWrapper>
  );
};

export default TeacherDropdownCard;
