import { FC, useState } from "react";

import { TType, useTranslate } from "../../../../../assets/translations";
import { MoneyCard } from "../../../../../assets/images/svg-elements/money-card";
import { FieldTime } from "../../../../../assets/images/svg-elements/field-time";
import { CalendarClearIcon } from "../../../../../assets/images/svg-elements/calendar-clear";

import ApprovePublicRequestButton from "../../lesson-request-action-buttons/approve-public-request/approve-public-request";

import ClarificationNeededPopup from "../../../../modals/clarification-needed/clarification-needed-popup";
import TeacherPublicRequestAcceptPopup from "../../../../modals/teacher-public-request-accept/teacher-public-request-accept";

import {
  TeacherPublicCardLevel,
  TeacherPublicCardPrice,
  TeacherPublicMessage,
  TeacherPublicCardButtons,
  TeacherPublicCardContainer,
  TeacherPublicRequestUserInfo,
  TeacherPublicRequestTimeInfo,
  TeacherPublicApllicants,
} from "./teacher-public-card-styled";
import { LessonRequestCardWrapper } from "../lesson-request-card-styled";

import { useFormattedMoreMessage } from "../../../../../hooks/useFormattedMoreMessage";
import { getCategoryIcon } from "../../../../../services/user-service";
import { formatPriceForCard } from "../../services/formatPriceForCard";

import CategoryFallback from "../../../../../assets/images/png/category-fallback.png";

import {
  ApplicationStatuses,
  GetRequestsLessonStatuses,
  KnowledgeLevelsNames,
  LessonsFrequency,
  LessonsFrequencyNames,
  RequestLesson,
} from "../../../../../types";
import { formatAge } from "../../../../../services/localization-service";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { StudentDirectCardStatusWrapper } from "../student-direct-card/student-direct-card-styled";
import LessonRequestStatus from "../../lesson-request-status/lesson-request-status";
import TeacherDropdownCard from "../../lesson-request-dropdown/teacher-dropdown/teacher-dropdown";
import { useIsResponsive } from "../../../../../hooks/useIsResponsive";
import WrongCategoryPopup from "../../../../modals/wrong-category/wrong-category";
import { StyledButton } from "../../../../elements/button";
import { UserIcon } from "../../../../../assets/images/svg-elements/user";

interface TeacherPublicCardProps {
  card: RequestLesson;
  t: TType;
  clarificationNeeded: boolean | undefined;
  categoryIds: string[];
}

const TeacherPublicCard: FC<TeacherPublicCardProps> = ({
  card,
  t,
  clarificationNeeded,
  categoryIds,
}) => {
  const [isAcceptPopupOpen, setIsAccpetPopupOpen] = useState<boolean>(false);
  const [isClarificationNeededPopupOpen, setIsClarificationNeededPopupOpen] =
    useState<boolean>(false);
  const [isWrongCategoryPopupOpen, setIsWrongCategoryPopupOpen] =
    useState<boolean>(false);

  const {
    jwtParsed: { user_id },
  } = useSelector((state: RootState) => state.tokensStore);

  const { isBigDesktop } = useIsResponsive();

  const { language } = useTranslate();

  const price = formatPriceForCard(card.desiredPriceRange);

  const {
    formattedMessageWithButton,
  } = useFormattedMoreMessage(card.message, !isBigDesktop ? 100 : 30, t);

  const applicant = card.applicants.find(
    (applicant) => applicant.applicantId === user_id
  );

  const isTeacherCategory = !!categoryIds.find(
    (category) => category === card.categoryId
  );

  const isLimitApplicants = card.applicants.filter(applicant => applicant.status === ApplicationStatuses.ACTIVE || applicant.status === ApplicationStatuses.ACCEPTED).length >= 10;

  return (
    <LessonRequestCardWrapper>
      <ClarificationNeededPopup
        t={t}
        open={isClarificationNeededPopupOpen}
        onCancel={() => setIsClarificationNeededPopupOpen(false)}
      />
      <TeacherPublicRequestAcceptPopup
        t={t}
        open={isAcceptPopupOpen}
        onCancel={() => setIsAccpetPopupOpen(false)}
        connectionRequestId={card.id}
      />
      <WrongCategoryPopup
        t={t}
        open={isWrongCategoryPopupOpen}
        onCancel={() => setIsWrongCategoryPopupOpen(false)}
      />

      <TeacherPublicCardContainer className={`container`} isApplicant={!!applicant}>
        <TeacherPublicRequestUserInfo>
          <img
            src={getCategoryIcon(card.categoryCode) || CategoryFallback}
            alt={`open-book icon`}
          />
          <div className="personal_info">
            <div>
              <p>{card.studentName}</p>
            </div>
            {card.categoryName && <p>{card.categoryName}</p>}
          </div>
        </TeacherPublicRequestUserInfo>

        <TeacherPublicCardPrice>
          <MoneyCard />
          <p>
            {price
              ? `${price} ${t("main-page.search-query.uah")}`
              : t("my-students.not-specified")}
          </p>
        </TeacherPublicCardPrice>

        <TeacherPublicRequestTimeInfo>
          <div>
            <FieldTime />
            <p>{card.desiredTime ?? t("my-students.not-specified")}</p>
          </div>
          <div>
            <CalendarClearIcon />
            <p>
              {card.numberOfClassesPerWeek
                ? `${LessonsFrequencyNames[card.numberOfClassesPerWeek]} ${
                    card.numberOfClassesPerWeek === LessonsFrequency.ONE
                      ? t("main-page.search-query.one_time_per_week")
                      : t("main-page.search-query.time_per_week")
                  }`
                : t("my-students.not-specified")}
            </p>
          </div>
        </TeacherPublicRequestTimeInfo>

        <TeacherPublicCardLevel>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.level")}
            </p>
            <p>
              {card.knowledgeLevel
                ? t(
                    `language-levels.${
                      KnowledgeLevelsNames[card.knowledgeLevel]
                    }`
                  )
                : t("my-students.not-specified")}
            </p>
          </div>
          <div>
            <p className="card-section-title">
              {t("lesson-requests.cards.age")}
            </p>
            <p>
              {card.age
                ? `${card.age} ${formatAge(+card.age, language, t)}`
                : t("my-students.not-specified")}
            </p>
          </div>
        </TeacherPublicCardLevel>

        <TeacherPublicMessage>
          {card.message ? (
            <div className="container">
              <p>{formattedMessageWithButton}</p>
            </div>
          ) : (
            <p>{t("my-students.not-specified")}</p>
          )}
        </TeacherPublicMessage>

        {!applicant && (
          <TeacherPublicApllicants>
            <UserIcon/>
            <p>{card.applicants.filter(applicant => applicant.status === ApplicationStatuses.ACTIVE || applicant.status === ApplicationStatuses.ACCEPTED).length} / 10</p>
          </TeacherPublicApllicants>
        )}

        {!applicant ? (
          card.status === GetRequestsLessonStatuses.CREATED ? (
            isLimitApplicants ? (
              <TeacherPublicCardButtons>
                <StyledButton disabled={isLimitApplicants} hintForDisabledButton={"Максимальна кількість охочих на цей запит"}>{t("lesson-requests.buttons.schedule")}</StyledButton>
              </TeacherPublicCardButtons>
            ) : (
              <TeacherPublicCardButtons>
                <ApprovePublicRequestButton
                  clarificationNeeded={clarificationNeeded ?? true}
                  setIsAcceptPopupOpen={setIsAccpetPopupOpen}
                  setIsClarificationNeededPopupOpen={
                    setIsClarificationNeededPopupOpen
                  }
                  setIsWrongCategoryPopupOpen={setIsWrongCategoryPopupOpen}
                  isTeacherCategory={isTeacherCategory}
                >
                  {t("lesson-requests.buttons.schedule")}
                </ApprovePublicRequestButton>
            </TeacherPublicCardButtons>
            )
          ) : (
            <StudentDirectCardStatusWrapper>
              <LessonRequestStatus status={card.status} t={t} />
            </StudentDirectCardStatusWrapper>
          )
        ) : (
          <StudentDirectCardStatusWrapper>
            <LessonRequestStatus
              status={
                applicant.status === ApplicationStatuses.ACCEPTED ||
                applicant.status === ApplicationStatuses.REJECTED
                  ? applicant.status
                  : card.status
              }
              t={t}
            />
          </StudentDirectCardStatusWrapper>
        )}
      </TeacherPublicCardContainer>

      {!!applicant && <TeacherDropdownCard teacher={applicant} t={t} />}
    </LessonRequestCardWrapper>
  );
};

export default TeacherPublicCard;
