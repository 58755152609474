import {connect} from "react-redux";

import {TeachingMeFooterComponent} from "./footer-element";
import {Authority} from "../../../types";
import {RootState} from "../../redux";


const mapStateToProps = (state: RootState) => {
    return {
        isTeacher: state.tokensStore.jwtParsed.authority === Authority.ROLE_TEACHER,
        isStudent: state.tokensStore.jwtParsed.authority === Authority.ROLE_STUDENT,
    }
}


export const TeachingMeFooter = connect(mapStateToProps)(TeachingMeFooterComponent);
