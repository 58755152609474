import {FC, Fragment, PropsWithChildren, ReactNode, useMemo, useState} from "react";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";
import {flatten} from "flat";

import {DEFAULT_VALUE, TranslationsContext} from "./translationsContext";
import {LANGUAGES_LS_KEY, Translations, TranslationsProviderProps} from "./translationsTypes";
import {loadFromLs} from "../../services/storage-service";
import {useTranslate} from "./useTranslate";


export const TranslationsProvider: FC<PropsWithChildren<TranslationsProviderProps>> = ({children, messages}) => {

		const [language, setLanguage] = useState<Translations>(loadFromLs(LANGUAGES_LS_KEY, "Failed to load your language settings") || DEFAULT_VALUE.language);

		const value = useMemo(() => ({language, setLanguage}), [
				language,
				setLanguage,
		]);


		return <TranslationsContext.Provider value={value}>
			<IntlProvider key={language}
										locale={language}
										textComponent={Fragment}
										messages={flatten(messages[language])}
			>
				<ConfigProviderWrapper>
					{children}
				</ConfigProviderWrapper>
			</IntlProvider>
		</TranslationsContext.Provider>;
};

const ConfigProviderWrapper:FC<{children: ReactNode}> = ({children})=> {
	const {antLocale} = useTranslate()

	return(
		<ConfigProvider locale={antLocale}>
			{children}
		</ConfigProvider>
	)
}