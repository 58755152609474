import {ChangeEvent, FC, useCallback, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import {DesktopHeader} from "./desktop-header";
import {TabletHeader} from "./tablet-header";
import {MobileHeader} from "./mobile-header";
import {Filter, TagsRemoveMethodsBindings} from "../../pages";
import {device} from "../../constants";
import {RootState} from "../../redux";
import {AdminProfile, setState, StudentProfile, TUserProfileData} from "../../../types";
import {LANGUAGES_LS_KEY, Translations, TType, useTranslate} from "../../../assets/translations";
import {sendAnalyticEvent} from "../../../services/user-service";
import {saveToLs} from "../../../services/storage-service";

interface Props {
  t: TType;
  avatarId: string;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  logOut: () => void;
  notificationsCount: number;
  setNotificationsCount: setState<number>;
  messagesCount: number;
  setMessagesCount: setState<number>;
  accessToken: string;
  isShowChooseRole: boolean;
  firstName: string;
  lastName: string;
  isTeacher: boolean;
  searchDrawerOpen: boolean;
  setSearchDrawerOpen: setState<boolean>;
  filterData: Filter;
  setFilterData: setState<Filter>;
  searchStatus: boolean;
  tags: TagsRemoveMethodsBindings[];
  setTags: setState<TagsRemoveMethodsBindings[]>;
  performSearch: () => void;
  clearAll: () => void;
  applySearchText: (event: ChangeEvent<HTMLInputElement>) => void;
  setShowRegistration: setState<boolean>;
  user: TUserProfileData | StudentProfile | AdminProfile | null;
  navigationDisabled: boolean;
}

export const TeachingMeHeaderComponent: FC<Props> = ({
                                                       t,
                                                       avatarId,
                                                       logOut,
                                                       notificationsCount,
                                                       setNotificationsCount,
                                                       messagesCount,
                                                       setMessagesCount,
                                                       accessToken,
                                                       firstName,
                                                       lastName,
                                                       isTeacher,
                                                       searchDrawerOpen,
                                                       setSearchDrawerOpen,
                                                       filterData,
                                                       setFilterData,
                                                       searchStatus,
                                                       tags,
                                                       setTags,
                                                       performSearch,
                                                       clearAll,
                                                       applySearchText,
                                                       user,
                                                       navigationDisabled
                                                     }) => {

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    query: `(${device.tabletMin}) and (${device.tabletMax})`,
  });

  const isDesktop = useMediaQuery({
    query: `(${device.desktop})`,
  });

  const isMobile = useMediaQuery({
    query: `(${device.mobileMax})`,
  });

  const {jwtParsed: {authority}} = useSelector((state: RootState) => state.tokensStore);

  const isAuthenticated = (): boolean => !!accessToken?.length;

  const signIn = useCallback(() => {
    sendAnalyticEvent({
      action: "login_btn_click",
      category: "user actions",
    });

    navigate("/login");

  }, [isMobile]);

  const signUp = useCallback(() => {
    sendAnalyticEvent({
      category: "user actions",
      action: "signin_btn_click",
    });

    navigate("/signup-choose-role");
  }, [isMobile]);

  const goHome = useCallback(() => navigate(isTeacher ? "/user/profile" : "/"), [isTeacher]);

  const {language, setLanguage} = useTranslate();

  const onLanguageChange = useCallback((value: string) => {
    saveToLs(LANGUAGES_LS_KEY, value);
    setLanguage(value as Translations);
  }, []);

  const openProfilePage = useCallback(() => navigate("/user/profile"), []);

  function handleMenuOpen() {
    setMenuOpen(prev => !prev)
  }

  if (isDesktop) {
    return <DesktopHeader t={t}
                          avatarId={avatarId}
                          notificationsCount={notificationsCount}
                          setNotificationsCount={setNotificationsCount}
                          messagesCount={messagesCount}
                          setMessagesCount={setMessagesCount}
                          logOut={logOut}
                          language={language}
                          onLanguageChange={onLanguageChange}
                          goHome={goHome}
                          signIn={signIn}
                          signUp={signUp}
                          authority={authority}
                          isAuthenticated={isAuthenticated}
                          isTeacher={isTeacher}
                          navigationDisabled={navigationDisabled}
    />;
  }

  if (isTablet) {
    return <TabletHeader t={t}
                         avatarId={avatarId}
                         logOut={logOut}
                         firstName={firstName}
                         lastName={lastName}
                         notificationsCount={notificationsCount}
                         setNotificationsCount={setNotificationsCount}
                         messagesCount={messagesCount}
                         setMessagesCount={setMessagesCount}
                         language={language}
                         onLanguageChange={onLanguageChange}
                         goHome={goHome}
                         signIn={signIn}
                         signUp={signUp}
                         openProfilePage={openProfilePage}
                         authority={authority}
                         isAuthenticated={isAuthenticated}
                         handleMenuOpen={handleMenuOpen}
                         menuOpen={menuOpen}
                         user={user}
                         navigationDisabled={navigationDisabled}/>;
  }

  return <MobileHeader t={t}
                       avatarId={avatarId}
                       logOut={logOut}
                       firstName={firstName}
                       lastName={lastName}
                       notificationsCount={notificationsCount}
                       setNotificationsCount={setNotificationsCount}
                       messagesCount={messagesCount}
                       setMessagesCount={setMessagesCount}
                       language={language}
                       onLanguageChange={onLanguageChange}
                       goHome={goHome}
                       signIn={signIn}
                       openProfilePage={openProfilePage}
                       authority={authority}
                       isAuthenticated={isAuthenticated}
                       handleMenuOpen={handleMenuOpen}
                       menuOpen={menuOpen}
                       searchDrawerOpen={searchDrawerOpen}
                       setSearchDrawerOpen={setSearchDrawerOpen}
                       filterData={filterData}
                       setFilterData={setFilterData}
                       searchStatus={searchStatus}
                       tags={tags}
                       setTags={setTags}
                       performSearch={performSearch}
                       clearAll={clearAll}
                       applySearchText={applySearchText}
                       user={user}
                       navigationDisabled={navigationDisabled}/>;
};