import styled from "styled-components";
import { StandartCardBlock } from "../teacher-public-card/teacher-public-card-styled";
import { device } from "../../../../constants";
import {
  CardContainer,
  LessonRequestCardTimeInfo,
  LessonRequestCardUserInfo,
  LessonRequestStatusWrapper,
} from "../lesson-request-card-styled";

export const TeacherDirectCardTimeSlots = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  flex-direction: row;

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 50%;
    justify-content: flex-start;
  }
`;

export const TeacherDirectCardTimeSlot = styled.div`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding: 4px 8px;
  background: #f5f5f5;

  p {
    font-size: 12px;
    line-height: 20px;
  }
`;

export const TeacherDirectAge = styled(StandartCardBlock)`
  div {
    display: flex;
    gap: 4px;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
    border-bottom: none;
  }
`;

export const TeacherDirectCardButtons = styled(StandartCardBlock)`
  flex-direction: row;
  gap: 16px;

  @media (${device.smallDesktop}) {
    border-bottom: none;
  }

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 100%;
    border-left: none;
  }

  @media (min-width: 1281px) and (max-width: 1550px) {
    grid-column: span 5;
    border-left: none;
  }
`;
export const TeacherDirectCardContainer = styled(CardContainer)`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    display: flex;
    flex-wrap: wrap;
    row-gap: 16px;
  }

  @media (${device.mobileMax}) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 1281px) and (max-width: 1550px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    row-gap: 16px;
  }
  
  @media (min-width: 1551px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`;

export const TeacherDirectCardUserInfo = styled(LessonRequestCardUserInfo)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
    border-bottom: none;
    border-right: 1px solid #d6e4ff;
    padding: 0;
    justify-content: flex-start;
  }
`;

export const TeacherDirectCardTimeInfo = styled(LessonRequestCardTimeInfo)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 33.3%;
    border-bottom: none;
    border-left: none;
  }

  @media (min-width: 1281px) and (max-width: 1550px) {
    > div:first-child {
      justify-content: center;
    }
  }
`;

export const TeacherDirectCardStatusWrapper = styled(
  LessonRequestStatusWrapper
)`
  @media (${device.tabletMin}) and (${device.smallDesktop}) {
    width: 50%;
  }
`;
