import { FC } from "react";

import { ButtonTheme, StyledButton } from "../../../../../elements/button";

import {
  StudentDropdownCardInfoWithPriceWrapper,
  StudentDropdownCardMessage,
  StudentDropdownCardPrice,
  StudentDropdownCardStatus,
  StudentDropdownCardUserInfo,
  StudentDropdownCardWrapper,
} from "./student-dropdown-card-styled";

import {
  ApplicationStatuses,
  GetRequestsLessonStatuses,
  RequestLesson,
  RequestLessonApplicants,
} from "../../../../../../types";
import { TType, useTranslate } from "../../../../../../assets/translations";
import { NavLink, useNavigate } from "react-router-dom";
import { getUsersPicture } from "../../../../../../services/user-service";
import { MoneyCard } from "../../../../../../assets/images/svg-elements/money-card";
import { useFormattedMoreMessage } from "../../../../../../hooks/useFormattedMoreMessage";
import LessonRequestStatus from "../../../lesson-request-status/lesson-request-status";
import OpenContactsButton from "../../../lesson-request-action-buttons/open-contacts/open-contacts";
import { useIsResponsive } from "../../../../../../hooks/useIsResponsive";
import { CardMessageButton } from "../../../lesson-request-action-buttons/lesson-request-action-buttons";

interface StudentDropdownCardProps {
  teacher: RequestLessonApplicants;
  connectionRequestId: string;
  studentId: string;
  t: TType;
  openAcceptModal: (applicantId: string) => void;
  openRejectModal: (applicantId: string) => void;
  card: RequestLesson;
}

const StudentDropdownCard: FC<StudentDropdownCardProps> = ({
  teacher,
  connectionRequestId,
  studentId,
  t,
  openAcceptModal,
  openRejectModal,
  card
}) => {
  const {
    message,
    price,
    applicantName,
    id: applicationId,
    applicantAvatarId,
    status,
    applicantId,
  } = teacher;

  const { language } = useTranslate();
  const navigate = useNavigate();

  const { isSmallDesktop, isTablet, isMobile, isBigDesktop } =
    useIsResponsive();

  const {
    formattedMessageWithButton,
  } = useFormattedMoreMessage(message, !isBigDesktop ? 100 : 30, t);

  const handleAcceptClick = () => {
    openAcceptModal(applicationId);
  };

  const handleRejectClick = () => {
    openRejectModal(applicationId);
  };

  const buttonDisableCondition =
    status !== ApplicationStatuses.ACTIVE &&
    status !== ApplicationStatuses.CONTACTS_OPENED;

  const showButtonsCondition =
    status !== ApplicationStatuses.ACCEPTED &&
    status !== ApplicationStatuses.REJECTED;

  return (
    <StudentDropdownCardWrapper>
      {(isSmallDesktop || isTablet) && (
        <StudentDropdownCardInfoWithPriceWrapper>
          <StudentDropdownCardUserInfo>
            <NavLink to={`/user/details/${applicantId}`}>
              <img
                src={getUsersPicture(applicantAvatarId)}
                alt="teacher avatar"
              />
            </NavLink>
            <div className="personal_info">
              <div>
                <NavLink to={`/user/details/${applicantId}`}>
                  <p>{applicantName}</p>
                </NavLink>
              </div>
            </div>
          </StudentDropdownCardUserInfo>

          <StudentDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </StudentDropdownCardPrice>

          <StudentDropdownCardStatus>
            <LessonRequestStatus status={status} t={t} />
          </StudentDropdownCardStatus>
        </StudentDropdownCardInfoWithPriceWrapper>
      )}

      {isMobile && (
        <StudentDropdownCardInfoWithPriceWrapper>
          <StudentDropdownCardUserInfo>
            <NavLink to={`/user/details/${applicantId}`}>
              <img
                src={getUsersPicture(applicantAvatarId)}
                alt="teacher avatar"
              />
            </NavLink>
            <div className="personal_info">
              <div>
                <NavLink to={`/user/details/${applicantId}`}>
                  <p>{applicantName}</p>
                </NavLink>
              </div>
            </div>
          </StudentDropdownCardUserInfo>

          <StudentDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </StudentDropdownCardPrice>
        </StudentDropdownCardInfoWithPriceWrapper>
      )}

      {isBigDesktop && (
        <>
          <StudentDropdownCardUserInfo>
            <NavLink to={`/user/details/${applicantId}`}>
              <img
                src={getUsersPicture(applicantAvatarId)}
                alt="teacher avatar"
              />
            </NavLink>
            <div className="personal_info">
              <div>
                <NavLink to={`/user/details/${applicantId}`}>
                  <p>{applicantName}</p>
                </NavLink>
              </div>
            </div>
          </StudentDropdownCardUserInfo>

          <StudentDropdownCardPrice>
            <div>
              <MoneyCard />
              <p>
                {price
                  ? `${price} ${t("main-page.search-query.uah")}`
                  : t("my-students.not-specified")}
              </p>
            </div>
          </StudentDropdownCardPrice>
        </>
      )}

      {!isTablet && !isSmallDesktop && (
        <StudentDropdownCardStatus>
          <LessonRequestStatus status={status} t={t} />
        </StudentDropdownCardStatus>
      )}

      <StudentDropdownCardMessage>
        {message ? (
          <div className="container">
            <p>{formattedMessageWithButton}</p>
          </div>
        ) : (
          <p>{t("my-students.not-specified")}</p>
        )}
      </StudentDropdownCardMessage>

      {showButtonsCondition && card.status === GetRequestsLessonStatuses.CREATED ? (
        <div className="buttons">
          <StyledButton theme={ButtonTheme.BLUE} onClick={handleAcceptClick}>
            {t("lesson-requests.buttons.accept")}
          </StyledButton>
          <StyledButton
            theme={ButtonTheme.WHITE}
            onClick={handleRejectClick}
            disabled={buttonDisableCondition}
          >
            {t("lesson-requests.buttons.decline")}
          </StyledButton>

          {status === ApplicationStatuses.CONTACTS_OPENED ? (
            <CardMessageButton
              language={language}
              navigate={navigate}
              studentId={studentId}
              t={t}
              teacherId={teacher.applicantId}
              theme={ButtonTheme.INVERT_BLUE}
              disabled={buttonDisableCondition}
              type="icon"
            />
          ) : (
            <OpenContactsButton
              language={language}
              navigate={navigate}
              studentId={studentId}
              teacherId={teacher.applicantId}
              theme={ButtonTheme.INVERT_BLUE}
              t={t}
              connectionRequestId={connectionRequestId}
              applicationId={applicationId}
              disabled={buttonDisableCondition}
            />
          )}
        </div>
      ) : (
        <CardMessageButton
          language={language}
          navigate={navigate}
          studentId={studentId}
          t={t}
          teacherId={teacher.applicantId}
          theme={ButtonTheme.INVERT_BLUE}
          type="icon"
        />
      )}
    </StudentDropdownCardWrapper>
  );
};

export default StudentDropdownCard;
