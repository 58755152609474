import {FC, memo, useState} from "react";
import {Popover} from "antd";
import {UserOutlined} from "@ant-design/icons";
import classNames from "classnames";

import {ProfileDropdown} from "./profile-dropdown";
import {NotificationsDropdown} from "./notifications-dropdown";
import {LanguageSwitch} from "../../language-switch";
import {Authority, setState, StepIds} from "../../../../types";
import {Translations, TType} from "../../../../assets/translations";
import {
  DesktopHeaderComponentWrapper,
  StyledAvatarImg,
  StyledLogo,
} from "./desktop-header.styled";
import {Underline} from "../../../../assets/images/svg-elements/underline";
import {getUsersPicture} from "../../../../services/user-service";
import {AffixStyled} from "../../../layout-components/affix/affix-styled";
import {Hint} from "../../hint";


interface Props {
  t: TType;
  avatarId: string;
  notificationsCount: number;
  setNotificationsCount: setState<number>;
  messagesCount: number;
  setMessagesCount: setState<number>;
  logOut: () => void;
  language: Translations;
  onLanguageChange: (value: string) => void;
  goHome: () => void;
  signIn: () => void;
  signUp: () => void;
  authority?: Authority;
  isAuthenticated: () => boolean;
  isTeacher: boolean;
  navigationDisabled: boolean;
}


export const DesktopHeader: FC<Props> = memo(({
                                                t,
                                                avatarId,
                                                notificationsCount,
                                                setNotificationsCount,
                                                messagesCount,
                                                setMessagesCount,
                                                logOut,
                                                language,
                                                onLanguageChange,
                                                goHome,
                                                signIn,
                                                signUp,
                                                authority,
                                                isAuthenticated,
                                                isTeacher,
                                                navigationDisabled
                                              }) => {

  const [profileDropdownOpen, setProfileDropdownOpen] = useState<boolean>(false);

  function handleProfileDropdownOpen() {
    setProfileDropdownOpen(prev => !prev);
  }

  return <AffixStyled>
    <DesktopHeaderComponentWrapper>
      <div className="header">
        <Hint showHint={navigationDisabled}
              placement="bottomLeft"
              title={t("registration.disabled-header-hint")}
        >
          <div className={classNames("header-logo", {"navigation-disabled": navigationDisabled})} data-cy="header-logo"
               onClick={() => !navigationDisabled && goHome()}>
            <StyledLogo/>
            <h4>{t("teaching-me")}
              <Underline/>
            </h4>
          </div>
        </Hint>

        <div className="header-buttons">
          {isAuthenticated() ?
            <>
              <LanguageSwitch t={t} language={language} onLanguageChange={onLanguageChange}
                              renderPopupInsideContainer={true}/>

              {/* <div className="menu-buttons-wrapper">
                            {authority === Authority.ROLE_STUDENT &&
                                <NavLink data-cy="my-favourites" id={StepIds.favourites} className="menu-button" to={"favourites"}>
                                    {t("favourites.header.caption")}
                                </NavLink>}
                            {isTeacher &&
                                <NavLink id={StepIds.schedule} className="menu-button" to={"schedule"}>
                                    {t("schedule.header")}
                                </NavLink>}
                            <NavLink data-cy="my-lessons" id={StepIds.lessons} className="menu-button" to={"lessons"}>
                                {t("lessons.header.title")}
                            </NavLink>
                        </div> */}
              <Hint
                showHint={navigationDisabled}
                placement="bottom"
                title={t("registration.disabled-header-hint")}
              >
                <div
                  className={classNames("notification-button-container", {"navigation-disabled": navigationDisabled})}>
                  <NotificationsDropdown t={t}
                                         notificationsCount={notificationsCount}
                                         setNotificationsCount={setNotificationsCount}
                                         messagesCount={messagesCount}
                                         setMessagesCount={setMessagesCount}/>

                  <Popover trigger={"click"}
                           zIndex={100}
                           open={profileDropdownOpen}
                           placement="bottomRight"
                           getPopupContainer={(triggerNode) => triggerNode.parentNode as HTMLElement}
                           onOpenChange={(open) => setProfileDropdownOpen(open)}
                           content={<ProfileDropdown t={t}
                                                     setProfileDropdownOpen={setProfileDropdownOpen}
                                                     avatarId={avatarId}
                                                     logOut={logOut}/>}>
                    <StyledAvatarImg id={StepIds.avatar}
                                     onClick={handleProfileDropdownOpen}
                                     src={getUsersPicture(avatarId)}
                                     loading="lazy"
                                     alt="Profile"/>
                  </Popover>
                </div>
              </Hint>
            </>
            :
            <>
              <LanguageSwitch t={t} language={language} onLanguageChange={onLanguageChange}
                              renderPopupInsideContainer={true}/>
              <div className="not-authorized-buttons-wrapper">

                <button className="login" onClick={signIn} data-cy="login-btn">
                  <UserOutlined/>
                  {t("login.form.button.signin")}
                </button>
                <button className="create-account" data-cy="sign-up-header-btn" onClick={signUp}>
                  {t("register.createAccount")}
                </button>
              </div>
            </>
          }
        </div>
      </div>

    </DesktopHeaderComponentWrapper>
  </AffixStyled>
});

