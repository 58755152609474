import { FC, useMemo } from "react";

import { CustomLoader } from "../../../elements/custom-loader";
import { NotFoundComponent } from "../../../elements/not-found-component";

import LessonRequestHeader from "../lesson-request-header/lesson-request-header";

import { StudentMenuTabs } from "../lesson-request";

import { UserLessonRequestProps } from "../teacher/teacher-lesson-request";

import { FAQMenu } from "../../../../types/FAQ";
import { LessonRequestType } from "../../../../types";
import StudentPublicCard from "../lesson-request-card/student-public-card/student-public-card";
import StudentDirectCard from "../lesson-request-card/student-direct-card/student-direct-card";

enum ReqTabs {
  ACTIVE_REQUESTS = "active-requests",
}

const SEARCH_MAPPING: Record<keyof StudentMenuTabs, boolean> = {
  active_requests: false,
};

const FILTER_INPUT_MAPPING: Record<keyof StudentMenuTabs, [boolean, boolean]> =
  {
    active_requests: [false, false],
  };

const StudentLessonRequest: FC<UserLessonRequestProps> = ({
  t,
  isLoading,
  requests,
  selectedKey,
  handleChangeSelectedKey,
  isSwitchActive,
  setIsSwitchActive,
  openDirectRequestsCount
}) => {
  const selectedStudentKey = selectedKey as keyof StudentMenuTabs;

  const MENU_TITLES: FAQMenu<StudentMenuTabs>[] = [
    {
      id: 0,
      label: t(`lesson-requests.tabs.${ReqTabs.ACTIVE_REQUESTS}`),
      key: "active_requests",
    },
  ];

  const REQUEST_LIMIT_MAPPING: Record<keyof StudentMenuTabs, boolean> = {
    active_requests: true,
  };

  const renderCards = useMemo(() => {
    if (isLoading) {
      return <CustomLoader />;
    }

    if (!requests || requests.length === 0) {
      return (
        <NotFoundComponent
          text={t("lesson-requests.not-found-student")}
          style={{ height: "60vh" }}
        />
      );
    }

    return (
      <div className="cards">
        {requests.map((request) =>
          request.type === LessonRequestType.STUDENT_DIRECT ? (
            <StudentDirectCard card={request} t={t} key={request.id} />
          ) : (
            <StudentPublicCard card={request} t={t} key={request.id} />
          )
        )}
      </div>
    );
  }, [isLoading, requests, t]);

  return (
    <>
      <LessonRequestHeader
        handleChangeSelectedKey={handleChangeSelectedKey}
        menuTitles={MENU_TITLES}
        selectedKey={selectedStudentKey}
        requestsLength={openDirectRequestsCount}
        t={t}
        handleRenderFilterInputs={(key) => FILTER_INPUT_MAPPING[key]}
        showRequestLimit={(key) => REQUEST_LIMIT_MAPPING[key]}
        isShowSearchFn={(key) => SEARCH_MAPPING[key]}
        isSwitchActive={isSwitchActive}
        switchLabel={t("lesson-requests.switch")}
        setIsSwitchActive={setIsSwitchActive}
        isShowActiveOnly
      />
      {renderCards}
    </>
  );
};

export default StudentLessonRequest;
