import { QueryClient } from "@tanstack/react-query";
import { putDirectLessonRequest } from "../../../../api/lesson-api/putDirectConnectionRequests";
import { GetRequestsLessonStatuses } from "../../../../types";
import {
  TeachingMeErrorNotification,
  TeachingMeSuccessNotification,
} from "../../../elements/notification";
import {
  createLessonDataKey,
  saveToLs,
} from "../../../../services/storage-service";
import { NavigateFunction } from "react-router-dom";
import { isAxiosError } from "axios";

interface handleChangeRequestStatusProps {
  newStatus: GetRequestsLessonStatuses;
  successMsg?: string;
  connectionRequestId: string;
  queryClient: QueryClient;
  teacherId: string;
  categoryId: string;
  studentId: string;
  navigate: NavigateFunction | null;
}

export const handleChangeRequestStatus = async ({
  connectionRequestId,
  newStatus,
  successMsg,
  queryClient,
  teacherId,
  studentId,
  categoryId,
  navigate,
}: handleChangeRequestStatusProps) => {
  try {
    const res = await putDirectLessonRequest(connectionRequestId, {
      status: newStatus,
    });
    if (res.status === 200) {
      {successMsg && TeachingMeSuccessNotification(successMsg)};

      if (newStatus === GetRequestsLessonStatuses.CANCELLED || newStatus === GetRequestsLessonStatuses.ACCEPTED) {
        queryClient.refetchQueries(["teacherDirectRequests"]);
        queryClient.refetchQueries(["ConnectionRequest"]);
      }

      // If teacher accepted, prepare for lesson creation
      if (newStatus === GetRequestsLessonStatuses.ACCEPTED && navigate) {
        saveToLs(createLessonDataKey, {
          teacherId,
          categoryId,
          studentId,
        });
        navigate("/lesson/create");
      }
      
      queryClient.refetchQueries(["UserProfile"]);
    }
  } catch (error) {
    if (isAxiosError(error)) {
      TeachingMeErrorNotification(error.message);
    }
  }
};
